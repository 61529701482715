import * as React from 'react'
import { Tooltip } from 'recharts'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts'
import Chart from 'shared/components/Charts/Chart'
import styles from 'shared/components/Charts/Chart.module.scss'

export type ComprehensionData = Array<{ day: string; value: number | null }>

const chartProps = {
  margin: {
    top: 15,
    left: 5,
    right: 0
  }
}

const yAxisProps = { domain: [0, 100] }

export const ComprehensionChart: React.FC<{
  data: ComprehensionData
  chartType?: string
}> = ({ data, chartType = 'line' }) => {
  if (chartType === 'line') {
    return (
      <Chart
        id={'comprehensionChart'}
        chartProps={chartProps}
        data={data}
        lineKey={'value'}
        lineGradient={{ start: '#18acb7', end: '#18acb7' }}
        yAxisProps={yAxisProps}>
        {data.length > 0 && <Tooltip content={<ComprehensionTooltip />} />}
      </Chart>
    )
  }

  if (chartType === 'bar') {
    return (
      <BarChart width={300} height={300} data={data} {...chartProps}>
        <CartesianGrid vertical={false} />
        <XAxis tickLine={false} axisLine={false} dataKey="day" stroke={'#c8c8c8'} />
        <YAxis tickLine={false} axisLine={false} width={40} stroke={'#c8c8c8'} {...yAxisProps} />
        <Tooltip content={<ComprehensionTooltip />} />
        <Bar dataKey="value" fill="#18acb7" />
      </BarChart>
    )
  }

  return null
}

const ComprehensionTooltip: React.FC<any> = ({ active, payload, ...props }) => {
  if (active && payload && payload[0]) {
    const { value } = payload[0].payload
    return (
      <div className={styles.tooltip}>
        <p>{~~value || 0}%</p>
      </div>
    )
  }

  return null
}
