import * as React from 'react'
import styles from './PageLoader.module.scss'

const PageLoader: React.FC = () => (
  <div className={styles.spinner}>
    <div className={styles.bounce1} />
    <div className={styles.bounce2} />
    <div className={styles.bounce3} />
  </div>
)

export default PageLoader
