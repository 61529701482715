import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { getAllBooks } from 'Book/api'
import { Typography, BookCarousel, Book, BookContext, Modal, Button, sortBooksByCEFR } from '@astrid/components'
import { ReactComponent as BookIcon } from 'shared/assets/book.svg'
import { BookType } from '@astrid/components/src/types'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './LibraryView.module.scss'

interface DifficultyLevel {
  cefrLevel: 'A1' | 'A2' | 'B1' | 'B2' | 'C1'
  label: string
}

const DIFFICULTY_LEVELS: DifficultyLevel[] = [
  { cefrLevel: 'A1', label: 'A1 (Beginner)' },
  { cefrLevel: 'A2', label: 'A2 (Elementary)' },
  { cefrLevel: 'B1', label: 'B1 (Intermediate)' },
  { cefrLevel: 'B2', label: 'B2 (Upper Intermediate)' },
  { cefrLevel: 'C1', label: 'C1+ (Expert)' }
]

interface LibraryViewState {
  loading: boolean
  books: Book[]
  bookToOpenId: string | null
}

const LibraryView = () => {
  useTrackScreenShown(ScreenNames.Library)

  const [state, setState] = useState<LibraryViewState>({
    loading: true,
    books: [],
    bookToOpenId: null
  })
  const history = useHistory()

  useEffect(() => {
    // GA_pageview(window.location.pathname)
    ;(async () => {
      try {
        const {
          data: { results }
        } = await getAllBooks()
        setState({
          loading: false,
          books: results,
          bookToOpenId: null
        })
      } catch (error) {
        setState((state) => ({
          ...state,
          loading: false
        }))
      }
    })()
  }, [])

  const onBookClick = useCallback(
    (bookId: string, bookTitle: string, bookType: BookType) => {
      switch (bookType) {
        case BookType.Reading:
          history.push(`/book/${BookContext.reading}/${bookId}`)
          break
        case BookType.Listening:
          history.push(`/book/${BookContext.listening}/${bookId}`)
          break
        case BookType.Mix:
          setState((s) => ({ ...s, bookToOpenId: bookId }))
          break
      }
    },
    [history]
  )

  const renderDifficultySection = useCallback(
    ({ label, cefrLevel }: DifficultyLevel) => {
      const booksToRender = state.books.filter((book) => book.cefrLevel?.includes(cefrLevel))
      if (booksToRender.length < 1) return null
      return (
        <div className={styles.difficultySection} key={`${cefrLevel}`}>
          <span className={styles.difficulty}>
            <BookIcon />
            <Typography variant="h3">{label}</Typography>
          </span>
          <BookCarousel
            displayTypeIcon
            books={sortBooksByCEFR(booksToRender)}
            loading={state.loading}
            onItemClick={onBookClick}
          />
        </div>
      )
    },
    [state.books, state.loading, onBookClick]
  )

  const sections = useMemo(() => DIFFICULTY_LEVELS.map((lvl) => renderDifficultySection(lvl)), [
    renderDifficultySection
  ])

  return (
    <div className={styles.container}>
      {sections}
      <Modal
        open={!!state.bookToOpenId}
        variant="teacher"
        hideCloseButton
        closeModal={() => setState((s) => ({ ...s, bookToOpenId: null }))}>
        <Typography variant="body" style={{ margin: '30px 0px 25px 0' }}>
          In which mode would you like to open this book?
        </Typography>
        <div className={styles.modalButtons}>
          <Button
            variant="flat"
            color="primary"
            onClick={() => history.push(`/book/${BookContext.reading}/${state.bookToOpenId}`)}>
            Reading
          </Button>
          <Button
            variant="flat"
            color="primary"
            onClick={() => history.push(`/book/${BookContext.listening}/${state.bookToOpenId}`)}>
            Listening
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default LibraryView
