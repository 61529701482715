import React, { useCallback, useContext, useState } from 'react'
import * as Yup from 'yup'
import { Formik, FormikHelpers } from 'formik'
import { Typography, TextInput, Button, AlertContext, Modal, ValidationRules } from '@astrid/components'
import { AuthCredentials } from 'store/services/Auth/types'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'store/types'
import { loginUser } from 'store/services/Auth/authReducer'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { printApiMessage } from 'shared/api/apiMessages'
import RecoverPassword from 'shared/components/RecoverPassword/RecoverPassword'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames, trackLogIn } from 'analytics/analytics'

import styles from './Login.module.scss'

const validationSchema = Yup.object().shape({
  email: ValidationRules.email,
  password: ValidationRules.required
})

const Login = () => {
  useTrackScreenShown(ScreenNames.LogIn)
  const { showAlert } = useContext(AlertContext)
  const [recoverPasswordModalOpen, setRecoverPasswordModalOpen] = useState(false)

  const dispatch = useDispatch<ThunkDispatch>()

  const handleSubmit = useCallback(
    async ({ email, password }: AuthCredentials, { setSubmitting }: FormikHelpers<AuthCredentials>) => {
      try {
        await dispatch(loginUser({ email, password }))
        trackLogIn()
      } catch ({ message }) {
        showAlert(printApiMessage(message))
        setSubmitting(false)
      }
    },
    [dispatch, showAlert]
  )

  return (
    <AuthWrapper>
      <Typography variant="h1" style={{ margin: 0 }}>
        Log in
      </Typography>
      <Typography variant="body">with your email and password</Typography>

      <Formik initialValues={{ email: '', password: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextInput
                name="email"
                label="Your Email"
                value={values.email}
                onChange={handleChange('email')}
                error={touched.email && !!errors.email}
                helperText={(touched.email && errors.email) || ''}
                onBlur={() => setFieldTouched('email')}
              />
              <TextInput
                name="password"
                label="Enter Password"
                type="password"
                value={values.password}
                onChange={handleChange('password')}
                error={touched.password && !!errors.password}
                helperText={(touched.password && errors.password) || ''}
                onBlur={() => setFieldTouched('password')}
              />
              <Button type="submit" variant="flat" color="action" disabled={isSubmitting}>
                Log in
              </Button>
            </form>
          )
        }}
      </Formik>
      <Typography
        variant="body"
        color="secondary"
        className={styles.forgot}
        onClick={() => setRecoverPasswordModalOpen(true)}>
        Forgot password?
      </Typography>
      <Typography variant="body" className={styles.contact}>
        <a href="https://www.astrideducation.com/contact" target="_blank" rel="noopener noreferrer">
          Contact us
        </a>
      </Typography>
      <Modal
        variant="student"
        hideCloseButton
        open={recoverPasswordModalOpen}
        closeModal={() => setRecoverPasswordModalOpen(false)}>
        <RecoverPassword closeModal={() => setRecoverPasswordModalOpen(false)} />
      </Modal>
    </AuthWrapper>
  )
}

export default Login
