import * as React from 'react'
import { useLocation, useHistory, NavLink } from 'react-router-dom'
import { AstridLogo, CloseIcon, HelpIcon, ProfileIcon } from '@astrid/components'
import { Link } from 'react-router-dom'

import styles from './Header.module.scss'
import { ROUTES } from 'routes/routes'

import { ReactComponent as BookIcon } from 'shared/assets/book.svg'
import { ReactComponent as InsightsIcon } from 'shared/assets/insights.svg'
import { ReactComponent as SettingsIcon } from 'shared/assets/settings.svg'
import { useCallback } from 'react'

const Header: React.FC = () => {
  const { pathname } = useLocation()
  const history = useHistory()

  const isBookRoute = pathname.includes(ROUTES.BOOK)
  const isStudentRoute = pathname.includes(ROUTES.STUDENT)
  const isClassRoute = pathname.includes(`${ROUTES.CLASS}/`)
  const showClose = isBookRoute || isStudentRoute || isClassRoute

  const goBack = useCallback(() => {
    if (isBookRoute) {
      history.push(ROUTES.LIBRARY)
      return
    }

    if (isStudentRoute) {
      history.push(ROUTES.INSIGHTS)
      return
    }

    history.goBack()
    //eslint-disable-next-line
  }, [pathname, history])

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        {showClose ? (
          <CloseIcon className={styles.close} onClick={goBack} />
        ) : (
          <Link to={ROUTES.INSIGHTS}>
            <AstridLogo className={styles.logo} />
          </Link>
        )}
        <div className={styles.controls}>
          <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.INSIGHTS}>
            <InsightsIcon />
            Insights
          </NavLink>
          <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.LIBRARY}>
            <BookIcon />
            Library
          </NavLink>
          <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.CLASSES}>
            <SettingsIcon />
            My Classes
          </NavLink>
          <NavLink className={styles.link} activeClassName={styles.active} to={ROUTES.PROFILE}>
            <ProfileIcon />
            Profile
          </NavLink>
          <a
            className={styles.link}
            href="https://www.astrideducation.com/faq"
            target="_blank"
            rel="noopener noreferrer">
            <HelpIcon />
            Help
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header
