import { combineReducers } from 'redux'
import { RootState } from './types'

import authReducer, { AuthInitialState } from 'store/services/Auth/authReducer'
import { AuthActions } from 'store/services/Auth/types'

import classesReducer, { ClassesInitialState } from 'store/services/Classes/reducer'
import { ClassesActions } from 'store/services/Classes/types'

import statisticsReducer, { StatisticsInitialState } from 'store/services/Statistics/reducer'
import { StatisticAction } from 'store/services/Statistics/types'

import studyTimeReducer, { StudyTimeInitialState } from 'store/services/StudyTime/reducer'
import { StudyTimeAction } from 'store/services/StudyTime/types'

import overviewReducer, { OverviewInitialState } from 'store/services/Overview/reducer'
import { OverviewActions } from 'store/services/Overview/types'

export const combinedReducers = combineReducers({
  auth: authReducer,
  classes: classesReducer,
  statistics: statisticsReducer,
  studyTime: studyTimeReducer,
  overview: overviewReducer
})

export const initialRootState: RootState = {
  auth: AuthInitialState,
  classes: ClassesInitialState,
  statistics: StatisticsInitialState,
  studyTime: StudyTimeInitialState,
  overview: OverviewInitialState
}

export const rootReducer = (
  state = initialRootState,
  action: AuthActions | ClassesActions | StatisticAction | StudyTimeAction | OverviewActions
) => combinedReducers(state, action)
