import React, { useState, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { ROUTES } from 'routes/routes'
import BookFactory from 'Book/BookFactory/BookFactory'
import { getAllBooks } from './api'
import { BookCover, Book, BookContext } from '@astrid/components'

import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import './BookView.scss'

interface IState {
  currentBook: Book | null
  loading: boolean
  coverOpen: boolean
}

const BookView = (props: RouteComponentProps<{ id: string; bookContext: BookContext }>) => {
  useTrackScreenShown(ScreenNames.Book)

  const bookId = props.match.params.id
  const bookContext = props.match.params.bookContext
  const [state, setState] = useState<IState>({
    loading: true,
    currentBook: null,
    coverOpen: true
  })

  if (!bookId) props.history.replace(ROUTES.INSIGHTS)

  useEffect(() => {
    // GA_pageview('/book')
    ;(async () => {
      try {
        const {
          data: { results }
        } = await getAllBooks()
        const currentBook = results.find((book) => book._id === bookId)
        if (!currentBook) {
          props.history.replace(ROUTES.INSIGHTS)
          return
        }
        setState((state) => ({
          ...state,
          loading: false,
          currentBook: currentBook
        }))
      } catch (error) {}
    })()
    //eslint-disable-next-line
  }, [])

  const onCompleteBook = () => {
    props.history.push(ROUTES.LIBRARY)
  }

  if (state.loading) {
    return null
  }

  if (state.coverOpen) {
    return (
      <div className="book-cover-wrapper">
        <BookCover
          disableContinue={false}
          book={state.currentBook!}
          bookContext={bookContext}
          onStart={() => setState((state) => ({ ...state, coverOpen: false }))}
        />
      </div>
    )
  } else if (state.currentBook) {
    return <BookFactory book={state.currentBook} onCompleteBook={onCompleteBook} bookContext={bookContext} />
  } else return null
}

export default withRouter(BookView)
