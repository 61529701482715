import React from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import { Statistic } from 'store/services/Statistics/types'
import { StatsOverview } from 'store/services/Overview/types'
import { ROUTES } from 'routes/routes'
import { StudentClass } from 'store/services/Classes/types'
import StudyTimeCell from 'shared/components/Overview/StudyTimeCell'

import styles from './InsightsTable.module.scss'

interface InsightsTableProps {
  statistics: Statistic[] | undefined
  total: StatsOverview | undefined
  selectedClass: StudentClass | undefined
  status: 'success' | 'error' | 'loading'
}

const InsightsTable: React.FC<InsightsTableProps> = ({ statistics, selectedClass, total, status = 'success' }) => {
  return (
    <section className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th colSpan={2}>Progress</th>
            <th colSpan={3}>Effort</th>
          </tr>
          <tr>
            <th />

            {/*Progress*/}
            <th>Fluency</th>
            <th>Comprehension</th>
            {/*Progress*/}

            {/*Effort*/}
            <th>Time studied</th>
            <th>Lessons completed</th>
            {/*Effort*/}
          </tr>
        </thead>
        <tbody>
          {(status === 'loading' || status === 'error') && (
            <tr>
              <td colSpan={6}>
                <span className={styles.message}>
                  {status === 'loading' ? (
                    <>
                      <CircularProgress className={styles.spinner} size={18} thickness={4} />
                      Loading...
                    </>
                  ) : (
                    'Failed to load data.'
                  )}
                </span>
              </td>
            </tr>
          )}

          {status === 'success' && (
            <>
              {!statistics || statistics.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <span className={styles.message}>This class is empty.</span>
                  </td>
                </tr>
              ) : (
                <>
                  {statistics.map((statistic) => {
                    return (
                      <tr key={statistic._id} data-testid={'BookListRow'}>
                        <td>
                          <Link to={`${ROUTES.STUDENT}/${selectedClass?._id}/${statistic._id}`}>
                            {`${statistic.firstName} ${statistic.lastName}`}
                          </Link>
                        </td>

                        {/*Progress*/}
                        <td>{~~statistic.progress.fluency.userScore} wpm</td>
                        <td>{~~statistic.progress.comprehension.userScore}%</td>
                        {/*Progress*/}

                        {/*Effort*/}
                        <StudyTimeCell
                          goal={statistic.effort.timeSpent.userGoal}
                          score={statistic.effort.timeSpent.userScore}>
                          {~~statistic.effort.timeSpent.userScore} min
                        </StudyTimeCell>
                        <td>{~~statistic.effort.booksCompleted.userScore}</td>
                        {/*Effort*/}
                      </tr>
                    )
                  })}
                  {total && (
                    <tr className={styles.total} data-testid={'BookListRow'}>
                      <td>
                        <Link to={`${ROUTES.CLASS}/${selectedClass?._id}`}>Total Average</Link>
                      </td>
                      <td>{total.stats?.progress.fluency.userScore} wpm</td>
                      <td>{total.stats?.progress.comprehension.userScore}%</td>

                      <StudyTimeCell score={total.stats?.effort.timeSpent.userScore}>
                        {total.stats?.effort.timeSpent.userScore} min
                      </StudyTimeCell>
                      <td>{total.stats?.effort.booksCompleted.userScore}</td>
                    </tr>
                  )}
                </>
              )}
            </>
          )}
        </tbody>
      </table>
    </section>
  )
}

export default InsightsTable
