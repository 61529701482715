import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from 'App'
import * as Sentry from '@sentry/react'
import { initHotjar } from '@astrid/components'
import { initGoogleTagManager } from 'shared/helpers/GoogleTagManager'

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_GTM_ID) {
  initGoogleTagManager(process.env.REACT_APP_GTM_ID)
}

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  const environment = process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment
  })
}

if (
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_HOTJAR_ID &&
  typeof process.env.REACT_APP_HOTJAR_ID === 'string'
) {
  initHotjar(process.env.REACT_APP_HOTJAR_ID)
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
