import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser, selectUser } from 'store/services/Auth/authReducer'
import PageLoader from 'shared/components/PageLoader/PageLoader'
import CreateProfile from 'Auth/CreateProfile/CreateProfile'
import { WelcomeAnimation } from '@astrid/components'
import Tutorial from 'Tutorial/Tutorial'
import { useRouteMatch } from 'react-router-dom'
import AuthApi from 'store/services/Auth/authApi'
import { VerificationStatus } from 'store/services/Auth/types'
// import { GAEventCategory, GAEventActions } from 'shared/constants/GA_events'

interface Props {
  authStack: React.ReactElement
}

const AuthWall: React.FC<Props> = ({ children, authStack }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [welcomeAnimation, setWelcomeAnimation] = useState(true)

  const matchEmailChange = useRouteMatch<{ token: string }>({
    path: '/auth/verify-change/:token',
    strict: true,
    exact: true
  })

  useEffect(() => {
    const shouldShowUnsupportedMessage =
      window.innerWidth < 1366 && process.env.NODE_ENV !== 'test' && !AuthApi.getUserToken()
    let isCancelled = false

    if (matchEmailChange) {
      try {
        AuthApi.verifyEmailChange(matchEmailChange.params.token)
      } catch (error) {}
    }

    ;(async () => {
      try {
        await dispatch(getUser())
      } catch (e) {
      } finally {
        !isCancelled && setLoading(false)
      }
    })()
    if (shouldShowUnsupportedMessage) {
      alert(
        'The website is not yet optimized for mobile devices and it will not work correctly on them. Please use the application on desktop devices.'
      )
    }

    return () => {
      isCancelled = true
    }
    // eslint-disable-next-line
  }, [])
  const user = useSelector(selectUser)
  const loggedIn = user !== undefined
  const emailConfirmed = user?.verificationStatus === VerificationStatus.VERIFIED
  const hasProfile = user?.profile
  const shouldShowWelcomeAnimation =
    loggedIn &&
    welcomeAnimation &&
    process.env.NODE_ENV !== 'development' &&
    process.env.NODE_ENV !== 'test' &&
    !AuthApi.getUserToken()

  if (loading) {
    return <PageLoader />
  }

  if (loggedIn && !hasProfile) {
    return <CreateProfile />
  }

  if (!loggedIn || !emailConfirmed) {
    return authStack
  }

  if (shouldShowWelcomeAnimation) {
    return <WelcomeAnimation onAnimationEnd={() => setWelcomeAnimation(false)} />
  }

  if (loggedIn && !user?.tutorialCompleted) {
    return <Tutorial />
  }

  return <>{children}</>
}

export default AuthWall
