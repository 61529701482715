const messages: { [key in string]: string } = {
  USER_NOT_FOUND: 'User not found',
  TEACHER_WRONG_CREDENTIALS: 'You have entered an invalid email or password.',
  MAXIMUM_NUMBER_OF_STUDENTS_IN_CLASS_EXCEEDED: 'Class can have up to 50 students in total.',
  DEFAULT: 'Something went wrong, try again later...'
}

const contactSupportMessage = ' Or contact us for help.'

export const printApiMessage = (error: unknown, withSupportAddress = false) => {
  let message: string
  if (typeof error === 'string') {
    message = error in messages ? messages[error] : error
  } else if (error instanceof Error) {
    message = messages[error.message] || messages.DEFAULT
  } else {
    message = messages.DEFAULT
  }
  message = withSupportAddress ? message + contactSupportMessage : message

  return message
}
