import React, { useCallback, useContext, useState } from 'react'
import { Typography, EditIcon, CloseIcon, CheckmarkIcon, AlertContext, Card } from '@astrid/components'
import { TextField, IconButton, Tooltip } from '@material-ui/core'

import styles from '../ClassView.module.scss'
import { useDispatch } from 'react-redux'
import { updateCurrentClass } from 'store/services/Classes/reducer'

interface ClassViewHeaderProps {
  name: string
  cefrLevel: string
  classId: string
}

interface ClassViewHeaderState {
  editName: boolean
  nameValue: string
  nameError: string
}

const ConfirmationButtons: React.FC<{ confirm: () => void; cancel: () => void }> = ({ confirm, cancel }) => {
  return (
    <div className={styles.header__confirmationButtons}>
      <Tooltip title="Save">
        <IconButton onClick={confirm}>
          <CheckmarkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Cancel">
        <IconButton onClick={cancel}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const ClassViewHeader: React.FC<ClassViewHeaderProps> = ({ name, classId }) => {
  const dispatch = useDispatch()
  const { showAlert } = useContext(AlertContext)
  const [state, setState] = useState<ClassViewHeaderState>({
    editName: false,
    nameValue: name,
    nameError: ''
  })

  const handleChange = useCallback((value: string) => {
    setState((s) => ({
      ...s,
      nameValue: value
    }))
  }, [])

  const handleCancel = useCallback(() => {
    setState((s) => ({
      editName: false,
      nameValue: name,
      nameError: ''
    }))
  }, [name])

  const handleSubmit = useCallback(async () => {
    if (state.nameValue.length > 50) {
      setState((s) => ({ ...s, nameError: 'Must be less than 50 characters.' }))
      return
    }
    if (state.nameValue === '') {
      setState((s) => ({ ...s, nameError: 'Required' }))
      return
    }
    try {
      await dispatch(updateCurrentClass(classId, { name: state.nameValue }))
      setState((s) => ({
        ...s,
        editName: false,
        nameError: ''
      }))
    } catch (error) {
      showAlert('Something went wrong, please try again.')
    }
  }, [classId, state, dispatch, showAlert])

  return (
    <Card className={styles.header}>
      <div className={styles.header__block}>
        {state.editName ? (
          <>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label="Class name"
              name="nameValue"
              value={state.nameValue}
              error={!!state.nameError}
              helperText={state.nameError}
              onChange={(e) => handleChange(e.target.value)}
            />
            <ConfirmationButtons cancel={handleCancel} confirm={handleSubmit} />
          </>
        ) : (
          <>
            <Typography variant="h3">{name}</Typography>
            <EditIcon
              className={styles.header__block__edit}
              onClick={() => setState((s) => ({ ...s, editName: true }))}
            />
          </>
        )}
      </div>
    </Card>
  )
}

export default ClassViewHeader
