import React, { useEffect } from 'react'
import { Button, Card, CheckmarkIcon, CloseIcon, Typography } from '@astrid/components'
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { FieldArray, FormikErrors, FormikTouched } from 'formik'
import StudentFormRow, { StudentFormRowValues } from 'shared/components/StudentFormRow/StudentFormRow'

import styles from '../SheetPreview.module.scss'

interface SheetPreviewFormProps {
  submitForm: () => void
  onClosePreview: () => void
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  validateForm: () => void
  isSubmitting: boolean
  values: { students: StudentFormRowValues[] }
  errors: FormikErrors<{ students: StudentFormRowValues[] }>
  touched: FormikTouched<{ students: StudentFormRowValues[] }>
}

const SheetPreviewForm: React.FC<SheetPreviewFormProps> = ({
  submitForm,
  values,
  handleBlur,
  handleChange,
  onClosePreview,
  errors,
  touched,
  validateForm,
  isSubmitting
}) => {
  useEffect(() => {
    validateForm()
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className={styles.header}>
        <Typography variant="h4">Uploaded file preview</Typography>
        <div>
          <Button
            variant="flat"
            size="small"
            color="primary"
            type="submit"
            onClick={submitForm}
            disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress className={styles.spinner} size={18} thickness={4} /> : <CheckmarkIcon />}
            Add students listed below
          </Button>
          <IconButton onClick={onClosePreview} disabled={isSubmitting}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <Card className={styles.card}>
        <Table className={styles.table}>
          <TableHead className={styles.head}>
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Parent name</TableCell>
              <TableCell>Parent email</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="students" validateOnChange={false}>
              {({ remove }) => {
                return values.students && values.students.length > 0
                  ? values.students.map((student, index) => (
                      <StudentFormRow
                        key={`student_preview_${index}`}
                        fieldArrayName={`students[${index}]`}
                        values={student}
                        validateWithoutTouch
                        errors={
                          errors?.students ? (errors?.students[index] as Partial<StudentFormRowValues>) : undefined
                        }
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        onCancel={() => remove(index)}
                        cancelTooltip="Delete row"
                        hideCredentialsPlaceholders
                      />
                    ))
                  : null
              }}
            </FieldArray>
          </TableBody>
        </Table>
        {values.students.length === 0 && (
          <Typography variant="body" style={{ textAlign: 'center', margin: 0, padding: '20px 40px' }}>
            There are no students left in this document. Please cancel and upload a new document or add students one by
            one!
          </Typography>
        )}
      </Card>
    </>
  )
}

export default SheetPreviewForm
