import React from 'react'

import { Typography } from '../../'

import './Headline.scss'

interface HeadlineProps {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  imgSrc?: string
  className?: string
  style?: React.CSSProperties
}

const Headline: React.FC<HeadlineProps> = ({ title, subtitle, imgSrc, className, style }) => (
  <div className={`headline ${className ? className : ''}`} style={style}>
    {imgSrc && <img src={imgSrc} alt="" />}
    <div>
      {typeof title === 'string' ? <Typography variant={'h2'}>{title}</Typography> : title}
      {typeof subtitle === 'string' ? (
        <Typography variant={'body'} color={'secondary'}>
          {subtitle}
        </Typography>
      ) : (
        subtitle
      )}
    </div>
  </div>
)

export default Headline
