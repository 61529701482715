import { WebEventing } from '@astrid/eventing'
import { Analytics } from './analyticsApi'

export enum ScreenNames {
  LogIn = 'Log in',
  ActivateEmail = 'Activate email',
  VerifyEmail = 'Verify email',
  ResetPassword = 'Reset password',
  InsightsOverview = 'Insights overview',
  InsightsStudent = 'Insights student',
  InsightsClass = 'Insights class',
  Library = 'Library',
  Book = 'Book',
  MyClasses = 'My classes',
  Class = 'Class',
  Profile = 'Profile'
}

export const trackScreenShown = (screenName: string) =>
  Analytics.track(new WebEventing.Events.WebScreenShown({ url: window.location.href, screen_name: screenName }))

export const trackLogIn = () =>
  Analytics.track(
    new WebEventing.Events.WebAction({ url: window.location.href, action_title: 'Log in', action_value: undefined })
  )
