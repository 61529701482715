import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { ValidationRules } from '@astrid/components'
import { Formik } from 'formik'

import StudentFormRow from 'shared/components/StudentFormRow/StudentFormRow'

export interface SingleStudentFormValues {
  firstName: string
  lastName: string
  parentName1: string
  parentEmail1: string
  parentName2: string
  parentEmail2: string
}

export interface StudentFormRowProps {
  onSubmit: (values: SingleStudentFormValues) => void
  onCancel: () => void
  initialValues?: Partial<SingleStudentFormValues>
}

const SingleStudentForm: React.FC<StudentFormRowProps> = ({ onSubmit, onCancel, initialValues }) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: ValidationRules.name.required('Required'),
        lastName: ValidationRules.name.required('Required'),
        parentName1: ValidationRules.name,
        parentEmail1: ValidationRules.emailNotRequired.max(250, 'Email must be at most 250 chars.'),
        parentName2: ValidationRules.name,
        parentEmail2: ValidationRules.emailNotRequired.max(250, 'Email must be at most 250 chars.')
      }),
    []
  )

  return (
    <Formik
      initialValues={{
        firstName: initialValues?.firstName || '',
        lastName: initialValues?.lastName || '',
        parentName1: initialValues?.parentName1 || '',
        parentEmail1: initialValues?.parentEmail1 || '',
        parentName2: initialValues?.parentName2 || '',
        parentEmail2: initialValues?.parentEmail2 || ''
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      {({ values, handleChange, errors, touched, handleSubmit, handleBlur }) => (
        <StudentFormRow
          handleBlur={handleBlur}
          handleChange={handleChange}
          onCancel={onCancel}
          values={values}
          errors={errors}
          handleConfirm={handleSubmit}
          touched={touched}
          withConfirmButton
        />
      )}
    </Formik>
  )
}

export default SingleStudentForm
