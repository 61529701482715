import * as React from 'react'
import { Bar, YAxis, Tooltip, BarChart, CartesianGrid, XAxis } from 'recharts'
import styles from 'shared/components/Charts/Chart.module.scss'

export type EffortData = Array<{
  day: string
  minutes: number | null
  exercisesCompleted?: number
  storiesCompleted?: number
}>

const separateChartProps = {
  margin: {
    top: 15,
    left: 5,
    right: 0
  }
}

export const EffortSingleChart: React.FC<{
  data: EffortData
  effortName: string
  defaultMaxY: number
}> = ({ data, effortName = 'minutes', defaultMaxY = 60 }) => {
  const yAxisProps = { domain: [0, defaultMaxY] }
  const TooltopContent = EffortTooltip(effortName)
  return (
    <BarChart width={300} height={300} data={data} {...separateChartProps}>
      <CartesianGrid vertical={false} />
      <XAxis tickLine={false} axisLine={false} dataKey="day" stroke={'#c8c8c8'} />
      <YAxis tickLine={false} axisLine={false} width={40} stroke={'#c8c8c8'} {...yAxisProps} />
      {data.length > 0 && <Tooltip content={<TooltopContent />} />}
      <Bar dataKey={effortName} fill="#FFBEA8" />
    </BarChart>
  )
}

const EffortTooltip: (effortName: string) => React.FC<any> = (effortName) => ({ active, payload }) => {
  if (active && payload && payload[0]) {
    const value: number = payload[0].payload[effortName]
    return (
      <div className={styles.tooltip}>
        <p>
          {effortName}: {Math.round(value || 0)}
        </p>
      </div>
    )
  }

  return null
}
