export interface AuthState {
  user?: TeacherData
}

export enum AUTH_ACTIONS {
  LOAD_DATA = 'AUTH_LOAD_DATA'
}

export interface AuthCredentials {
  email: string
  password: string
}

export enum AuthRoles {
  Admin = 'admin',
  User = 'user',
  Teacher = 'teacher'
}

export enum VerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFIED = 'VERIFIED',
  CHANGED = 'CHANGED',
  NO_EMAIL_USER = 'NO_EMAIL_USER'
}

export interface TeacherData {
  _id: string
  email: string
  googleId?: string
  onboardingCompleted: boolean
  tutorialCompleted: boolean
  profile?: TeacherProfile
  verificationStatus: VerificationStatus
}

export interface TeacherProfile {
  firstName: string
  lastName: string
  school: string
  city: string
  country: string
  avatar?: string
  phone: string
}

export interface TeacherProfileUpdate {
  firstName?: string
  lastName?: string
  school?: string
  phone?: string
  avatar?: null
}

export interface AuthLoadUser {
  type: AUTH_ACTIONS.LOAD_DATA
  user: TeacherData
}

export type AuthActions = AuthLoadUser
