import React from 'react'
import { TableCell } from '@material-ui/core'
import { ReactComponent as ArrowDown } from 'shared/assets/arrow-down.svg'

import styles from '../StudentsTable.module.scss'
import { StudentsTableRow } from 'store/services/Classes/types'
import { SortingOrder } from '../StudentsTable'

interface SortableColumnHeaderProps {
  name: keyof StudentsTableRow
  sortKey: keyof StudentsTableRow
  order: SortingOrder
  label: string
  onClick: (key: keyof StudentsTableRow) => void
}

const SortableColumnHeader: React.FC<SortableColumnHeaderProps> = ({ name, order, sortKey, label, onClick }) => {
  return (
    <TableCell>
      <span onClick={() => onClick(name)} className={styles.headerCell}>
        {label}
        {sortKey === name && <ArrowDown style={order === 'dsc' ? undefined : { transform: `rotate(180deg)` }} />}
      </span>
    </TableCell>
  )
}

export default SortableColumnHeader
