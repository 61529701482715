import React from 'react'

import './ToggleSwitch.scss'

interface ToggleSwitchProps {
  name: string
  value: boolean
  trueLabel: string
  falseLabel: string
  onChange: () => void
  className?: string
  style?: React.CSSProperties
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  name,
  value,
  trueLabel,
  falseLabel,
  onChange,
  className,
  style
}) => {
  return (
    <div className={`toggle-switch ${className ? className : ''}`} style={style}>
      <input type="radio" name={name} id={falseLabel} checked={!value} onChange={onChange} />
      <label htmlFor={falseLabel}>{falseLabel}</label>
      <input type="radio" name={name} id={trueLabel} checked={value} onChange={onChange} />
      <label htmlFor={trueLabel}>{trueLabel}</label>
    </div>
  )
}

export default ToggleSwitch
