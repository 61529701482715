import { AxiosPromise } from 'axios'

import API from 'shared/api/API'
import { EffortAnalytics, Overview, ProgressAnalytics } from './types'

class OverviewApi {
  static getStudentOverview = (studentId: string, month?: boolean): AxiosPromise<Overview> =>
    API.get(`/insight/${studentId}`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })

  static getStudentProgressAnalytics = (studentId: string, month?: boolean): AxiosPromise<ProgressAnalytics> =>
    API.get(`/insight/${studentId}/progress/date`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })

  static getStudentEffortAnalytics = (studentId: string, month?: boolean): AxiosPromise<EffortAnalytics> =>
    API.get(`/insight/${studentId}/effort/date`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })

  static getStudentProfile = (studentId: string): AxiosPromise<StudentProfile> => API.get(`/user/profile/${studentId}`)

  static getClassOverview = (classId: string, month?: boolean): AxiosPromise<Overview> =>
    API.get(`/insight/${classId}/class`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })

  static getClassProgressAnalytics = (classId: string, month?: boolean): AxiosPromise<ProgressAnalytics> =>
    API.get(`/insight/${classId}/progress/date/class`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })

  static getClassEffortAnalytics = (classId: string, month?: boolean): AxiosPromise<EffortAnalytics> =>
    API.get(`/insight/${classId}/effort/date/class`, {
      params: {
        period: `${month ? 30 : 7}`
      }
    })
}

export interface StudentProfile {
  globalScore: number
  goals: {
    timeSpent: number
    booksCompleted: number
    exercisesCompleted: number
  }
  learningGoals: {
    fluency: number
    comprehension: number
    timeSpent: number
    booksCompleted: number
    exercisesCompleted: number
  }
  firstName: string
  lastName: string
  parent: {
    name: string
    email: string
  }
  school: {
    name: string
  }
  country: string
  grade: number
  avatar?: string
}

export default OverviewApi
