import { AxiosPromise } from 'axios'

import API from 'shared/api/API'
import { Statistic, StudentGoals } from './types'

class StatisticsApi {
  static getStatisticsByClassId = (classId: string, days?: number): AxiosPromise<Statistic[]> =>
    API.get(`/students-class/statistics/${classId}`, {
      params: {
        $filter: `period:${days}`
      }
    })
  static updateStudentsGoals = (classId: string, studentsGoals: StudentGoals[]): AxiosPromise<Statistic[]> =>
    API.patch(`/students-class/statistics/${classId}`, studentsGoals)
}

export default StatisticsApi
