import ApiService from './ApiService'
import AuthApi from 'store/services/Auth/authApi'

export const ACCESS_TOKEN_KEY = 'Authorization'

const API = new ApiService({
  API_URL: process.env.REACT_APP_API_URL,
  DEFAULT_HTTP_HEADERS: {
    'Content-Type': 'application/json'
  },
  ACCESS_TOKEN_KEY: ACCESS_TOKEN_KEY,
  GET_ACCESS_TOKEN: () => AuthApi.getUserToken()
}).service

export default API
