import React from 'react'
import Button from '../Button/Button'

import './CancelConfirmButtons.scss'

interface CancelConfirmButtonsProps {
  onCancel?: () => void
  onConfirm?: () => void
  cancelText?: string
  confirmText?: string
  className?: string
  disableConfirm?: boolean
  color?: 'action' | 'primary'
}

const CancelConfirmButtons: React.FC<CancelConfirmButtonsProps> = ({
  onCancel,
  onConfirm,
  className,
  cancelText,
  confirmText,
  disableConfirm,
  color = 'action'
}) => {
  return (
    <div className={`cancel-confirm-buttons ${className ? className : ''}`}>
      <Button variant="filled" disabled={disableConfirm} onClick={onConfirm} color={color}>
        {confirmText ? confirmText : 'Confirm'}
      </Button>
      <Button variant="outlined" onClick={onCancel}>
        {cancelText ? cancelText : 'Cancel'}
      </Button>
    </div>
  )
}

export default CancelConfirmButtons
