import { StudentProfile } from '@astrid/components'
import { ApiReqState } from 'shared/api/types'

export interface ClassesState {
  readonly classes: StudentClass[]
  readonly currentClass: StudentClassFilled | null
  readonly currentClassReqState: ApiReqState
  readonly insightClassId: string
}

export enum CLASSES_ACTIONS {
  LOAD_CLASSES = 'CLASSES/load',
  REMOVE_CLASS = 'CLASSES/remove',
  LOAD_CURRENT_CLASS = 'CLASSES/load_single',
  LOAD_CURRENT_CLASS_REQ_STATE = 'CLASSES/load_single_req_state',
  SET_INSIGHT_CLASS_ID = 'CLASSES/set_insight_class_id'
}

// Typings:

export interface StudentClassData extends Pick<StudentClass, 'name' | 'cefrLevel'> {}

export interface StudentClass {
  _id: string
  name: string
  cefrLevel: string
  users: string[]
}

export interface StudentsTableRow {
  _id: string
  firstName: string
  lastName: string
  username: string
  password: string
  parentName1?: string
  parentName2?: string
  parentEmail1?: string
  parentEmail2?: string
}

export interface SheetParsingResponse {
  parsedStudents: CreateStudentData[]
  invalidUsernamesIndexes: number[]
}

export interface StudentClassFilled extends Omit<StudentClass, 'users'> {
  users: Student[]
}

export interface CreateStudentData {
  firstName: string
  lastName: string
  username?: string
  password?: string
  parent?: {
    name1?: string
    email1?: string
    name2?: string
    email2?: string
  }
}

export interface Student {
  _id: string
  username: string
  password: string
  onboardingCompleted: boolean
  profile: StudentProfile
}

// Actions:

export interface LoadClassesAction {
  type: CLASSES_ACTIONS.LOAD_CLASSES
  classes: StudentClass[]
}

export interface LoadCurrentClassAction {
  type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS
  class: StudentClassFilled | null
}

export interface RemoveClassAction {
  type: CLASSES_ACTIONS.REMOVE_CLASS
  classId: string
}

export interface LoadCurrentClassReqStateAction {
  type: CLASSES_ACTIONS.LOAD_CURRENT_CLASS_REQ_STATE
  reqState: ApiReqState
}

export interface SetInsightClassIdAction {
  type: CLASSES_ACTIONS.SET_INSIGHT_CLASS_ID
  insightClassId: string
}

export type ClassesActions =
  | LoadClassesAction
  | LoadCurrentClassAction
  | RemoveClassAction
  | LoadCurrentClassReqStateAction
  | SetInsightClassIdAction
