import React, { useState } from 'react'
import { Typography, DownloadIcon, CancelConfirmButtons } from '@astrid/components'
import XLSXInput from './XLSXInput'

import styles from './UploadClassModal.module.scss'

const XLSX_TEMPLATE_DOWNLOAD_URL = 'https://static-production.withastrid.com/static/Astrid_Class_List_Template.xlsx'
const CSV_TEMPLATE_DOWNLOAD_URL = 'https://static-production.withastrid.com/static/Astrid_Class_List_Template.csv'

interface UploadClassModalProps {
  closeModal: (flag: boolean) => void
  onFileUpload: (file: File) => void
}

const UploadClassModal: React.FC<UploadClassModalProps> = ({ closeModal, onFileUpload }) => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null)
  return (
    <div className={styles.container}>
      <Typography variant="h2">Upload class list</Typography>
      <div className={styles.uploadStep}>
        <Typography variant="h4">Step 1</Typography>
        <Typography variant="body">
          Download the Excel template below and fill it with your students' details.
        </Typography>
        <div>
          <a download href={XLSX_TEMPLATE_DOWNLOAD_URL}>
            <DownloadIcon />
            Download template (XLSX)
          </a>
          <a download href={CSV_TEMPLATE_DOWNLOAD_URL}>
            <DownloadIcon />
            Download template (CSV)
          </a>
        </div>
      </div>
      <div className={styles.uploadStep}>
        <Typography variant="h4">Step 2</Typography>
        <Typography variant="body">Upload the completed template:</Typography>
        <XLSXInput onFileUpload={setUploadedFile} />
      </div>
      <CancelConfirmButtons
        confirmText="Add students"
        onCancel={() => closeModal(false)}
        disableConfirm={!uploadedFile}
        onConfirm={() => onFileUpload(uploadedFile!)}
      />
    </div>
  )
}

export default UploadClassModal
