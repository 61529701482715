import React from 'react'
import ProgressSegment from './ProgressSegment'
import { Progress } from 'store/services/Overview/types'
import { FluencyData, FluencyChart } from 'shared/components/Charts/FluencyChart'
import { ComprehensionData, ComprehensionChart } from 'shared/components/Charts/ComprehensionChart'
import styles from './OverviewSections.module.scss'

interface ProgressSectionProps {
  progress: Progress
  analytics: {
    fluency: FluencyData
    fluencyGoal: number | undefined
    comprehension: ComprehensionData
  }
  displayAverage?: boolean
}

const ProgressSection: React.FC<ProgressSectionProps> = ({ progress, analytics, displayAverage }) => {
  return (
    <div className={styles.progressIndicators}>
      <ProgressSegment
        data={progress.fluency}
        progressColor="#18acb7"
        size="regular"
        title={
          <>
            Fluency<span>(words per min)</span>
          </>
        }
        scoreSubtitle={displayAverage ? 'avg.' : ''}
      />
      <ProgressSegment
        data={progress.comprehension}
        progressColor="#18acb7"
        size="regular"
        title={
          <>
            Comprehension<span>(percentage)</span>
          </>
        }
        scoreSubtitle={displayAverage ? 'avg.' : ''}
      />
      <div className={styles.progressChart}>
        <FluencyChart data={analytics.fluency} chartType={'bar'} />
      </div>
      <div className={styles.progressChart}>
        <ComprehensionChart data={analytics.comprehension} chartType={'bar'} />
      </div>
    </div>
  )
}

export default ProgressSection
