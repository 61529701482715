import { AxiosPromise } from 'axios'

import { Paginated } from 'shared/api/types'
import API from 'shared/api/API'
import { StudentClass, StudentClassFilled, StudentClassData, CreateStudentData, SheetParsingResponse } from './types'

class ClassesApi {
  static create = (classData: StudentClassData): AxiosPromise<StudentClass> => API.post('/students-class', classData)
  static getClasses = (): AxiosPromise<Paginated<StudentClass>> => API.get('/students-class')
  static getClassById = (id: string): AxiosPromise<StudentClassFilled> => API.get(`/students-class/${id}`)
  static updateClass = (id: string, classData: Partial<StudentClassData>): AxiosPromise<StudentClassFilled> =>
    API.put(`/students-class/${id}`, classData)
  static deleteStudentFromClass = (classId: string, studentId: string): AxiosPromise<StudentClassFilled> =>
    API.delete(`/students-class/user/${classId}/${studentId}`)
  static addStudentToClass = (classId: string, studentData: CreateStudentData): AxiosPromise<StudentClassFilled> =>
    API.put(`/students-class/user/${classId}`, studentData)
  static deleteClass = (classId: string): AxiosPromise => API.delete(`/students-class/${classId}`)
  static editStudent = (
    classId: string,
    studentId: string,
    studentData: CreateStudentData
  ): AxiosPromise<StudentClassFilled> => API.patch(`/students-class/user/${classId}/${studentId}`, studentData)
  static addMultipleStudents = (classId: string, students: CreateStudentData[]): AxiosPromise<StudentClassFilled> =>
    API.put(`/students-class/${classId}/import`, { parsedStudents: students })

  static parseSheet = (classId: string, file: File): AxiosPromise<SheetParsingResponse> => {
    const data = new FormData()
    data.append('file', file)
    return API.put(`/students-class/${classId}/preview`, data)
  }
  static checkUsernameAvailability = (username: string): AxiosPromise<{ available: boolean }> =>
    API.post('/user/available', { username })

  static sendCredentialsEmailToParents = (classId: string) => API.get(`students-class/${classId}/email-parents`)
}

export default ClassesApi
