import { SingleStudentFormValues } from 'Classes/StudentsTable/components/SingleStudentForm'
import { StudentFormRowValues } from 'shared/components/StudentFormRow/StudentFormRow'
import ClassesApi from './api'
import { CreateStudentData, Student, StudentsTableRow } from './types'

export const checkIfUsernameAvailable = async (value: string | null | undefined): Promise<boolean> => {
  if (value) {
    try {
      const {
        data: { available }
      } = await ClassesApi.checkUsernameAvailability(value)
      return available
    } catch (error) {
      return true
    }
  }
  return true
}

export const formatStudentDataForApi = (values: SingleStudentFormValues | StudentFormRowValues): CreateStudentData => {
  const {
    parentName1,
    parentName2,
    parentEmail1,
    parentEmail2,
    firstName,
    lastName,
    username,
    password
  } = values as StudentFormRowValues
  const parent = [parentName1, parentName2, parentEmail1, parentEmail2].some((v) => v !== '')
    ? {
        parent: {
          ...(parentName1 && { name1: parentName1 }),
          ...(parentName2 && { name2: parentName2 }),
          ...(parentEmail1 && { email1: parentEmail1 }),
          ...(parentEmail2 && { email2: parentEmail2 })
        }
      }
    : {}
  return {
    firstName,
    lastName,
    ...(username && username !== '' && { username }),
    ...(password && password !== '' && { password }),
    ...parent
  }
}

export const transformPreviewStudentsFromApiToForm = (
  studentsPreviewFromApi: CreateStudentData[]
): StudentFormRowValues[] => {
  return studentsPreviewFromApi.map((student) => ({
    firstName: student.firstName || '',
    lastName: student.lastName || '',
    username: student.username || '',
    password: student.password || '',
    parentName1: student.parent?.name1 || '',
    parentEmail1: student.parent?.email1 || '',
    parentName2: student.parent?.name2 || '',
    parentEmail2: student.parent?.email2 || ''
  }))
}

export const transformStudentsFromApiToTable = (studentsFromApi: Student[]): StudentsTableRow[] => {
  return studentsFromApi.map((student) => ({
    firstName: student.profile.firstName,
    lastName: student.profile.lastName,
    username: student.username,
    password: student.password,
    parentName1: student.profile.parent?.name1,
    parentName2: student.profile.parent?.name2,
    parentEmail1: student.profile.parent?.email1,
    parentEmail2: student.profile.parent?.email2,
    _id: student._id
  }))
}
