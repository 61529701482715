import * as React from 'react'
import styles from './Dashboard.module.scss'

interface Props {
  children: React.ReactElement
}

const Dashboard: React.FC<Props> = ({ children }) => {
  return (
    <>
      <main className={styles.container}>
        <div className={styles.inner}>{children}</div>
      </main>
    </>
  )
}

export default Dashboard
