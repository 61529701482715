import API from 'shared/api/API'
import { TeacherData, AuthCredentials, TeacherProfile, TeacherProfileUpdate } from './types'
import { AxiosPromise } from 'axios'
import Cookies from 'shared/helpers/Cookies'

interface AuthorizationResponse {
  user: TeacherData
  token: string
}

class AuthApi {
  static getUserToken = () => Cookies.getItem('teacher_token')
  static setUserToken = (token: string) => Cookies.setItem('teacher_token', token, 100)
  static removeUserToken = () => Cookies.removeItem('teacher_token')
  static login = (data: AuthCredentials): AxiosPromise<AuthorizationResponse> => API.post('/auth/teacher', data)
  static create = (data: AuthCredentials): AxiosPromise<AuthorizationResponse> => API.post('/auth/teacher/signup', data)
  static createProfile = (data: TeacherProfile): AxiosPromise<TeacherData> => API.post('/teacher/profile', data)
  static load = (): AxiosPromise<TeacherData> => API.get('/teacher')
  static verify = (token: string) => API.get(`/auth/verify/${token}`)
  static verifyEmailChange = (token: string) => API.get(`/auth/verify-change/${token}`)
  static setOnboardingCompleted = (): AxiosPromise<TeacherData> => API.patch('/teacher', { onboardingCompleted: true })
  static setTutorialCompleted = (): AxiosPromise<TeacherData> => API.patch('/teacher', { tutorialCompleted: true })
  static logout = () => {
    AuthApi.removeUserToken()
    window.location.reload()
  }
  static sendRecoverPasswordEmail = (email: string) => API.get(`/auth/teacher/reset-pass/${email}`)
  static setNewPassword = (password: string, token: string) =>
    API.post('/auth/reset-pass', { password }, { headers: { authorization: token } })
  static resendVerificationEmail = () => API.post('/auth/resend')
  static deleteAccount = () => API.delete('/teacher')
  static changePassword = (password: string) => API.patch('/teacher', { password })
  static requestEmailChange = (email: string) => API.patch('/teacher', { email })
  static updateProfile = (data: TeacherProfileUpdate): AxiosPromise<TeacherData> => API.patch('/teacher/profile', data)
  static setProfileAvatar = (data: FormData): AxiosPromise<TeacherData> => API.put('/teacher/profile/avatar', data)
}

export default AuthApi
