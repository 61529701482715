import React, { Component } from 'react'
import * as Yup from 'yup'
import { TableRow, TableCell, TextField, Tooltip, IconButton } from '@material-ui/core'
import { CloseIcon, ValidationRules, CheckmarkIcon } from '@astrid/components'
import styles from './StudentFormRow.module.scss'

export interface StudentFormRowValues {
  firstName: string
  lastName: string
  username?: string
  password?: string
  parentName1: string
  parentEmail1: string
  parentName2: string
  parentEmail2: string
}

type TouchedFields = {
  [key in keyof StudentFormRowValues]?: boolean
}

export const StudentFormRowValidationSchema = Yup.object().shape({
  firstName: ValidationRules.name.required('Required'),
  lastName: ValidationRules.name.required('Required'),
  parentName1: ValidationRules.name,
  parentEmail1: ValidationRules.emailNotRequired.max(250, 'Email must be at most 250 chars.'),
  parentName2: ValidationRules.name,
  parentEmail2: ValidationRules.emailNotRequired.max(250, 'Email must be at most 250 chars.')
})

interface StudentFormRowProps {
  values: StudentFormRowValues
  fieldArrayName?: string
  touched?: TouchedFields
  errors?: Partial<StudentFormRowValues>
  withConfirmButton?: boolean
  validateWithoutTouch?: boolean
  cancelTooltip?: string
  hideCredentialsPlaceholders?: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  handleBlur: (e: React.FocusEvent<any>) => void
  onCancel: () => void
  handleConfirm?: () => void
}

class StudentFormRow extends Component<StudentFormRowProps> {
  shouldComponentUpdate(nextProps: StudentFormRowProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) return true
    return false
  }

  private displayError = (name: keyof StudentFormRowValues): string | undefined => {
    const { touched, errors } = this.props
    if (errors) {
      if (this.props.validateWithoutTouch) {
        return errors[name]
      } else if (touched) {
        return touched[name] && errors[name] ? errors[name] : undefined
      }
      return undefined
    }
    return undefined
  }

  render() {
    const {
      fieldArrayName,
      values,
      handleChange,
      handleBlur,
      cancelTooltip,
      onCancel,
      withConfirmButton,
      handleConfirm
    } = this.props
    return (
      <TableRow>
        <TableCell>
          <TextField
            type="text"
            id={fieldArrayName ? `${fieldArrayName}_firstName_input` : 'student_firstName_input'}
            name={fieldArrayName ? `${fieldArrayName}.firstName` : 'firstName'}
            label="First name"
            required
            size="small"
            variant="outlined"
            value={values.firstName}
            onChange={handleChange}
            error={!!this.displayError('firstName')}
            helperText={this.displayError('firstName')}
            onBlur={handleBlur}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="text"
            id={fieldArrayName ? `${fieldArrayName}_lastName_input` : 'student_lastName_input'}
            name={fieldArrayName ? `${fieldArrayName}.lastName` : 'lastName'}
            label="Last name"
            required
            size="small"
            variant="outlined"
            value={values.lastName}
            onChange={handleChange}
            error={!!this.displayError('lastName')}
            helperText={this.displayError('lastName')}
            onBlur={handleBlur}
          />
        </TableCell>
        {!this.props.hideCredentialsPlaceholders && (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
        <TableCell>
          <div className={styles.verticalCell}>
            <TextField
              type="text"
              name={fieldArrayName ? `${fieldArrayName}.parentName1` : 'parentName1'}
              id={fieldArrayName ? `${fieldArrayName}_parentName1_input` : 'student_parentName1_input'}
              label="Parent name 1"
              size="small"
              margin="dense"
              variant="outlined"
              value={values.parentName1}
              error={!!this.displayError('parentName1')}
              helperText={this.displayError('parentName1')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              type="text"
              name={fieldArrayName ? `${fieldArrayName}.parentName2` : 'parentName2'}
              id={fieldArrayName ? `${fieldArrayName}_parentName2_input` : 'student_parentName2_input'}
              label="Parent name 2"
              size="small"
              variant="outlined"
              margin="dense"
              value={values.parentName2}
              error={!!this.displayError('parentName2')}
              helperText={this.displayError('parentName2')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </TableCell>
        <TableCell>
          <div className={styles.verticalCell}>
            <TextField
              type="text"
              fullWidth
              name={fieldArrayName ? `${fieldArrayName}.parentEmail1` : 'parentEmail1'}
              id={fieldArrayName ? `${fieldArrayName}_parentEmail1_input` : 'student_parentEmail1_input'}
              label="Parent email 1"
              size="small"
              margin="dense"
              variant="outlined"
              value={values.parentEmail1}
              onChange={handleChange}
              error={!!this.displayError('parentEmail1')}
              helperText={this.displayError('parentEmail1')}
              onBlur={handleBlur}
            />
            <TextField
              type="text"
              fullWidth
              name={fieldArrayName ? `${fieldArrayName}.parentEmail2` : 'parentEmail2'}
              id={fieldArrayName ? `${fieldArrayName}_parentEmail2_input` : 'student_parentEmail2_input'}
              label="Parent email 2"
              size="small"
              margin="dense"
              variant="outlined"
              value={values.parentEmail2}
              onChange={handleChange}
              error={!!this.displayError('parentEmail2')}
              helperText={this.displayError('parentEmail2')}
              onBlur={handleBlur}
            />
          </div>
        </TableCell>
        <TableCell align="right">
          {withConfirmButton && (
            <Tooltip title="Save">
              <IconButton type="submit" onClick={handleConfirm as any} data-testid="student-add-confirm-button">
                <CheckmarkIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={cancelTooltip || 'Cancel'}>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }
}

export default StudentFormRow
