import { AlertContext, Button, TextInput, Typography, ValidationRules } from '@astrid/components'
import AuthWrapper from 'Auth/AuthWrapper/AuthWrapper'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'
import { Formik, FormikHelpers } from 'formik'
import React, { useCallback, useContext, useState } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { ROUTES } from 'routes/routes'
import { printApiMessage } from 'shared/api/apiMessages'
import AuthApi from 'store/services/Auth/authApi'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  password: ValidationRules.password,
  password2: Yup.string()
    .oneOf([Yup.ref('password'), ''], "Passwords don't match")
    .required('Required')
})

interface FormFields {
  password: string
  password2: string
}

const ResetPassword = ({ match, history }: RouteComponentProps<{ resetToken: string }>) => {
  useTrackScreenShown(ScreenNames.ResetPassword)
  const { resetToken } = match.params
  const [success, setSuccess] = useState(false)
  const { showAlert } = useContext(AlertContext)
  if (!resetToken || resetToken === '') {
    history.push(ROUTES.ROOT)
  }

  const handleSubmit = useCallback(
    async ({ password }: FormFields, { setSubmitting }: FormikHelpers<FormFields>) => {
      try {
        await AuthApi.setNewPassword(password, resetToken)
        setSuccess(true)
      } catch (error) {
        const message = error instanceof Error ? error.message : error
        setSubmitting(false)
        showAlert(printApiMessage(message))
      }
    },
    // eslint-disable-next-line
    []
  )

  return (
    <AuthWrapper>
      {success ? (
        <>
          <Typography variant="h3">The password has been changed.</Typography>
          <Typography variant="body" color="secondary">
            You can now <NavLink to={ROUTES.ROOT}>log in</NavLink>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h3">Set new password for your account</Typography>
          <Formik
            initialValues={{ password: '', password2: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ values, touched, errors, handleChange, setFieldTouched, handleSubmit, isSubmitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <TextInput
                    name="password"
                    label="New password"
                    type="password"
                    value={values.password}
                    onChange={handleChange('password')}
                    error={touched.password && !!errors.password}
                    helperText={(touched.password && errors.password) || ''}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <TextInput
                    name="password2"
                    label="Confirm new password"
                    type="password"
                    value={values.password2}
                    onChange={handleChange('password2')}
                    error={touched.password2 && !!errors.password2}
                    helperText={(touched.password2 && errors.password2) || ''}
                    onBlur={() => setFieldTouched('password2')}
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    Set password
                  </Button>
                </form>
              )
            }}
          </Formik>
        </>
      )}
    </AuthWrapper>
  )
}

export default withRouter(ResetPassword)
