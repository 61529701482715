import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { Headline, Typography } from '@astrid/components'
import { ROUTES } from 'routes/routes'
import Overview from 'shared/components/Overview/Overview'
import { getClassOverview, selectClassOverview, selectClassOverviewReqState } from 'store/services/Overview/reducer'

import { CircularProgress } from '@material-ui/core'
import { ApiReqState } from 'shared/api/types'
import { selectUser } from 'store/services/Auth/authReducer'
import { getCurrentClass, selectCurrentClass } from 'store/services/Classes/reducer'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './ClassOverview.module.scss'

const ClassOverview = ({ match }: RouteComponentProps<{ classId: string; id: string }>) => {
  useTrackScreenShown(ScreenNames.InsightsClass)
  // For now this overview is only for current week
  const displayMonth = false

  const classId = match.params.classId
  const currentClass = useSelector(selectCurrentClass)
  const classOverview = useSelector(selectClassOverview)
  const classOverviewReqState = useSelector(selectClassOverviewReqState)
  const teacher = useSelector(selectUser)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getClassOverview(classId, displayMonth))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [history, displayMonth, classId, dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCurrentClass(classId))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [classId, history, dispatch])

  if (!currentClass || !teacher?.profile) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Headline title={currentClass.name} subtitle={teacher.profile.school} imgSrc={teacher.profile.avatar} />
      </div>
      {classOverviewReqState === ApiReqState.PENDING && (
        <div className={styles.message}>
          <CircularProgress className={styles.spinner} size={18} thickness={4} />
          <Typography variant="body" className={styles.message}>
            Loading...
          </Typography>
        </div>
      )}
      {classOverviewReqState === ApiReqState.REJECTED && (
        <Typography variant="body" className={styles.message}>
          Failed to load data
        </Typography>
      )}
      {classOverviewReqState === ApiReqState.RESOLVED && classOverview && (
        <Overview
          overview={classOverview}
          displayMonth={displayMonth}
          showChallengingWordsToPronounce={true}
          showChallengingWordsToSpell={true}
          showChallengingSounds={true}
          showTrending={true}
          showOnlyTimeEffort={false}
          progressSubtitle="Total average"
          effortSubtitle="Total average"
        />
      )}
    </div>
  )
}

export default ClassOverview
