import React from 'react'

import { CircularProgressBar } from '@astrid/components'
import { OverviewDetails } from 'store/services/Overview/types'

import styles from './OverviewSections.module.scss'

interface ProgressSegmentProps {
  size: 'small' | 'regular'
  data: OverviewDetails
  title: string | JSX.Element
  progressColor: string
  textColor?: string
  scoreSubtitle?: string
}

const ProgressSegment: React.FC<ProgressSegmentProps> = ({
  size,
  data,
  title,
  progressColor,
  textColor,
  scoreSubtitle
}) => (
  <div className={styles.progressSegment}>
    <p>{title}</p>
    <CircularProgressBar
      justForShow
      backgroundColor="#ececec"
      progressColor={progressColor}
      percentage={100}
      size={size === 'regular' ? 200 : 180}
      strokeWidth={5}>
      <div
        className={styles.progressContentSmall}
        style={{ backgroundColor: progressColor, color: textColor ? textColor : 'white' }}>
        {~~data.userScore}
        <small>{scoreSubtitle}</small>
      </div>
    </CircularProgressBar>
  </div>
)

export default ProgressSegment
