import React from 'react'
import ProgressSegment from './ProgressSegment'
import { Effort } from 'store/services/Overview/types'
import { EffortData, EffortSingleChart } from 'shared/components/Charts/EffortSingleChart'
import styles from './OverviewSections.module.scss'

interface EffortSectionProps {
  effort: Effort
  analytics: {
    data: EffortData
    storiesGoal: number | undefined
    exercisesGoal: number | undefined
  }
}

const EffortSection: React.FC<EffortSectionProps> = ({ effort, analytics }) => {
  return (
    <div className={styles.progressIndicators}>
      <ProgressSegment
        data={effort.timeSpent}
        progressColor="#FFBEA8"
        size="regular"
        title={
          <>
            Time studied<span>(min)</span>
          </>
        }
      />
      <ProgressSegment data={effort.booksCompleted} progressColor="#FFBEA8" size="regular" title="Lessons completed" />
      <div className={styles.effortChart}>
        <EffortSingleChart data={analytics.data} effortName="minutes" defaultMaxY={60} />
      </div>
      <div className={styles.effortChart}>
        <EffortSingleChart data={analytics.data} effortName="storiesCompleted" defaultMaxY={10} />
      </div>
    </div>
  )
}

export default EffortSection
