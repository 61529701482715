import * as React from 'react'
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom'
import AuthWall from 'routes/AuthWall/AuthWall'
import Login from 'Auth/Login/Login'
import Header from 'shared/components/Header/Header'
import { ROUTES } from 'routes/routes'
import Insights from 'Insights/Insights'
import Dashboard from 'Dashboard/Dashboard'
import StudentOverview from 'StudentOverview/StudentOverview'
import BookView from 'Book/BookView'
import LibraryView from 'Library/LibraryView'
import ActivateEmail from 'Auth/ActivateEmail/ActivateEmail'
import VerifyEmail from 'Auth/VerifyEmail/VerifyEmail'
import Classes from 'Classes/Classes'
import Profile from 'Profile/Profile'
import CreateClass from 'Classes/CreateClass/CreateClass'
import ResetPassword from 'Auth/ResetPassword/ResetPassword'
import ClassView from 'Classes/ClassView/ClassView'
import EmailWall from './EmailWall/EmailWall'
import ClassOverview from 'ClassOverview/ClassOverview'
import { Analytics } from 'analytics/analyticsApi'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/services/Auth/authReducer'

const RouteStack: React.FC = () => {
  const user = useSelector(selectUser)

  React.useEffect(() => {
    user?._id && Analytics.identify(user._id, user)
  }, [user?._id])

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthWall authStack={<AuthStack />}>
        <Header />
        <Dashboard>
          <Switch>
            <Route exact path={ROUTES.INSIGHTS} component={Insights} />
            <Route exact path={`${ROUTES.STUDENT}/:classId/:id`} component={StudentOverview} />
            <Route exact path={`${ROUTES.CLASS}/:classId`} component={ClassOverview} />
            <Route exact path={`${ROUTES.BOOK}/:bookContext/:id`} component={BookView} />
            <Route exact path={`${ROUTES.LIBRARY}`} component={LibraryView} />
            <Route exact path={`${ROUTES.CLASSES}/:id`} component={ClassView} />
            <Route exact path={`${ROUTES.CLASSES}`} component={Classes} />
            <Route exact path={`${ROUTES.CLASSES_CREATE}`} component={CreateClass} />
            <Route exact path={ROUTES.PROFILE} component={Profile} />
            <Redirect to={ROUTES.INSIGHTS} />
          </Switch>
        </Dashboard>
      </AuthWall>
    </Router>
  )
}

const AuthStack: React.FC = () => {
  return (
    <EmailWall>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={Login} />
        <Route path={`${ROUTES.ACTIVATE_EMAIL}/:email`} component={ActivateEmail} />
        <Route path={`${ROUTES.VERIFY_EMAIL}/:token`} component={VerifyEmail} />
        <Route path={`${ROUTES.RESET_PASSWORD}`} component={ResetPassword} />
        <Redirect to={ROUTES.ROOT} />
      </Switch>
    </EmailWall>
  )
}

export default RouteStack
