import React, { useState, useCallback, useContext, useMemo } from 'react'

import { Table, TableHead, TableCell, TableBody, TableRow, IconButton, Tooltip } from '@material-ui/core'
import StudentTableToolbar from './components/StudentTableToolbar'
import { AlertContext, Card, Typography } from '@astrid/components'
import SingleStudentForm, { SingleStudentFormValues } from './components/SingleStudentForm'
import SortableColumnHeader from './components/SortableColumnHeader'
import { ReactComponent as DeleteIcon } from 'shared/assets/delete.svg'
import { ReactComponent as EditIcon } from 'shared/assets/edit.svg'
import { StudentsTableRow } from 'store/services/Classes/types'

import styles from './StudentsTable.module.scss'

export type SortingOrder = 'asc' | 'dsc'

interface StudentsTableProps {
  students: StudentsTableRow[]
  exportStudentsUrl: string
  onAddStudent: (values: SingleStudentFormValues) => Promise<void>
  onEditStudent: (studentId: string, values: SingleStudentFormValues) => Promise<void>
  onDeleteStudent: (student: StudentsTableRow) => void
  onUploadClassList: () => void
  onEmailParents: () => void
}

interface SortState {
  key: keyof StudentsTableRow
  order: SortingOrder
}

const StudentsTable: React.FC<StudentsTableProps> = ({
  students,
  onAddStudent,
  exportStudentsUrl,
  onEditStudent,
  onDeleteStudent,
  onUploadClassList,
  onEmailParents
}) => {
  const [addStudent, setAddStudent] = useState(false)
  const [sortState, setSortState] = useState<SortState>({ key: 'firstName', order: 'dsc' })
  const { showAlert } = useContext(AlertContext)
  const [editId, setEditId] = useState<string | null>(null)

  const _onAddStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      try {
        await onAddStudent(values)
        setAddStudent(false)
      } catch (error) {}
    },
    [onAddStudent]
  )

  const _onEditStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      if (editId) {
        try {
          await onEditStudent(editId, values)
          setEditId(null)
        } catch (error) {
          showAlert('Editing student error. Please try again.')
        }
      }
    },
    [showAlert, editId, onEditStudent]
  )

  const sortedStudents = useMemo(() => {
    return students.sort((a, b) => {
      return (sortState.order === 'asc' ? -1 : 1) * (a[sortState.key] || '').localeCompare(b[sortState.key] || '')
    })
  }, [students, sortState])

  const changeSorting = useCallback((key: keyof StudentsTableRow) => {
    setSortState((prevSortState) => {
      if (prevSortState.key === key) {
        return {
          ...prevSortState,
          order: prevSortState.order === 'asc' ? 'dsc' : 'asc'
        }
      } else {
        return {
          key,
          order: 'dsc'
        }
      }
    })
  }, [])

  return (
    <Card className={styles.card}>
      <StudentTableToolbar
        onAddStudent={() => setAddStudent(true)}
        exportStudentsUrl={exportStudentsUrl}
        studentsCount={students.length}
        onUploadClassList={onUploadClassList}
        onEmailParents={onEmailParents}
      />
      <Table className={styles.table}>
        <TableHead className={styles.head}>
          <TableRow>
            <SortableColumnHeader
              label="First name"
              name="firstName"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Last name"
              name="lastName"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Username"
              name="username"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <SortableColumnHeader
              label="Password"
              name="password"
              sortKey={sortState.key}
              order={sortState.order}
              onClick={changeSorting}
            />
            <TableCell>
              <span className={styles.headerCell__disabled}>Parent name</span>
            </TableCell>
            <TableCell>
              <span className={styles.headerCell__disabled}>Parent email</span>
            </TableCell>
            <TableCell align="right">
              <span className={styles.headerCell__actions}>Actions</span>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addStudent && <SingleStudentForm onSubmit={_onAddStudent} onCancel={() => setAddStudent(false)} />}
          {sortedStudents.map((row) => {
            if (editId && row._id === editId) {
              return (
                <SingleStudentForm
                  key={row._id}
                  onSubmit={_onEditStudent}
                  onCancel={() => setEditId(null)}
                  initialValues={{
                    firstName: row.firstName,
                    lastName: row.lastName,
                    parentName1: row.parentName1,
                    parentEmail1: row.parentEmail1,
                    parentName2: row.parentName2,
                    parentEmail2: row.parentEmail2
                  }}
                />
              )
            } else {
              return (
                <TableRow key={row.username} className={styles.row}>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.password}</TableCell>
                  <TableCell>
                    <div className={styles.verticalCell}>
                      {row.parentName1 && <p>{row.parentName1}</p>}
                      {row.parentName2 && <p>{row.parentName2}</p>}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={styles.verticalCell}>
                      {row.parentEmail1 && <p>{row.parentEmail1}</p>}
                      {row.parentEmail2 && <p>{row.parentEmail2}</p>}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Edit student">
                      <IconButton onClick={() => setEditId(row._id)} data-testid="student-edit-button">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete student">
                      <IconButton onClick={() => onDeleteStudent(row)} data-testid="student-delete-button">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )
            }
          })}
        </TableBody>
      </Table>
      {sortedStudents.length === 0 && !addStudent && (
        <Typography variant="body" className={styles.noStudentsInfo}>
          Add the whole class at once with 'Upload class list', or add individual students with 'Add student'
        </Typography>
      )}
    </Card>
  )
}

export default StudentsTable
