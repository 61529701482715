import * as React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import initStore from 'store/store'
import RouteStack from 'routes/RouteStack'
import { SoundEffectsProvider, AlertProvider } from '@astrid/components'
import './shared/constants/variables.module.scss'
import './index.scss'

function App() {
  const store = initStore()

  return (
    <ReduxProvider store={store}>
      <SoundEffectsProvider>
        <AlertProvider>
          <RouteStack />
        </AlertProvider>
        <div id="draggable" />
      </SoundEffectsProvider>
    </ReduxProvider>
  )
}

export default App
