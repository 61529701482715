import React, { useEffect, useState } from 'react'

import { ReactComponent as CirclePlusIcon } from 'shared/assets/plus-circle.svg'
import { Typography, Button, Modal } from '@astrid/components'
import ClassesList from './ClassesList/ClassesList'
import { useSelector, useDispatch } from 'react-redux'
import { selectClasses, getClasses } from 'store/services/Classes/reducer'
import CreateClass from './CreateClass/CreateClass'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './Classes.module.scss'

const Classes = () => {
  useTrackScreenShown(ScreenNames.MyClasses)
  const classes = useSelector(selectClasses)
  const dispatch = useDispatch()
  const [addClassModalOpen, setAddClassModalOpen] = useState(false)

  useEffect(() => {
    try {
      dispatch(getClasses())
    } catch (error) {}
  }, [dispatch])

  return (
    <section className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h2">My Classes</Typography>
        <Button color="action" onClick={() => setAddClassModalOpen(true)} className={styles.add_btn}>
          <CirclePlusIcon /> Create new class
        </Button>
      </div>
      {classes.length > 0 ? (
        <ClassesList classes={classes} />
      ) : (
        <Typography variant="exerciseS">
          Hi! This is where you add students to your class. Start by clicking "Create New Class".
        </Typography>
      )}
      <Modal open={addClassModalOpen} closeModal={() => setAddClassModalOpen(false)} hideCloseButton variant="teacher">
        <CreateClass onCancel={() => setAddClassModalOpen(false)} />
      </Modal>
    </section>
  )
}

export default Classes
