import { Dispatch } from 'redux'
import { createSelector } from 'reselect'

import StatisticsAPI from './api'

import * as Types from './types'
import { RootState } from 'store/types'
import { Logger } from 'shared/logger/Logger'
import { ApiReqState } from 'shared/api/types'

export const StatisticsInitialState: Types.StatisticsState = {
  entities: [],
  reqState: ApiReqState.IDLE
}

export default (state: Types.StatisticsState = StatisticsInitialState, action: Types.StatisticAction) => {
  switch (action.type) {
    case Types.STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID:
      return {
        ...state,
        entities: [...state.entities.filter((entity) => entity.classId !== action.entity.classId), action.entity]
      }
    case Types.STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID_REQ_STATE:
      return {
        ...state,
        reqState: action.reqState
      }
    default:
      return state
  }
}

/** ACTION CREATORS */

const setLoadStatisticsByClassIdReqState = (reqState: ApiReqState): Types.LoadStatisticsByClassIdReqStateAction => ({
  type: Types.STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID_REQ_STATE,
  reqState
})

export const loadStatisticsByClassId = (classId: string, fourWeeks?: boolean) => async (
  dispatch: Dispatch<Types.LoadStatisticsByClassIdAction | Types.LoadStatisticsByClassIdReqStateAction>
) => {
  try {
    dispatch(setLoadStatisticsByClassIdReqState(ApiReqState.PENDING))
    const { data } = await StatisticsAPI.getStatisticsByClassId(classId, fourWeeks ? 30 : 7)
    dispatch({
      type: Types.STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID,
      entity: {
        classId,
        statistics: data
      }
    })
    dispatch(setLoadStatisticsByClassIdReqState(ApiReqState.RESOLVED))
  } catch (error) {
    dispatch(setLoadStatisticsByClassIdReqState(ApiReqState.REJECTED))
    Logger.log(error)
  }
}

export const updateClassGoals = (classId: string, goals: Types.StudentGoals[]) => async (
  dispatch: Dispatch<Types.LoadStatisticsByClassIdAction>
) => {
  try {
    const { data } = await StatisticsAPI.updateStudentsGoals(classId, goals)
    dispatch({
      type: Types.STATISTICS_ACTIONS.LOAD_STATISTICS_BY_CLASS_ID,
      entity: {
        classId,
        statistics: data
      }
    })
  } catch (error) {
    Logger.log(error)
  }
}

/** SELECTORS */
export const selectStatistics = (state: RootState) => state.statistics.entities
export const selectStatisticsReqState = (state: RootState) => state.statistics.reqState

export const selectStatisticsByClassId = createSelector(
  selectStatistics,
  (_: any, classId: string) => classId,
  (entities, classId) => entities.find((entity) => entity.classId === classId)?.statistics
)
