import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ReactComponent as ArrowRight } from 'shared/assets/arrow-right.svg'

import { Typography, Button, useWindowSize } from '@astrid/components'
import { setTutorialCompleted } from 'store/services/Auth/authReducer'
import { ROUTES } from 'routes/routes'
import styles from './Tutorial.module.scss'

import rightGraphic from '@astrid/components/src/graphics/abby_jumping.png'
import leftGraphic from '@astrid/components/src/graphics/welcome-left.png'

const Tutorial: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { width } = useWindowSize()

  const onFinish = async () => {
    await dispatch(setTutorialCompleted())
    history.push(ROUTES.LIBRARY)
  }

  return (
    <div className={styles.container}>
      <Typography variant="h2">Welcome to Astrid!</Typography>
      <Typography variant="body" color="secondary">
        Watch our introduction video to learn more!
      </Typography>
      <iframe
        title="onboarding_video"
        style={{
          width: width! <= 1450 ? 816 : 921,
          height: width! <= 1450 ? 459 : 518,
          marginTop: 10,
          border: 'none',
          zIndex: 2
        }}
        src="https://www.youtube.com/embed/q8FQgR7pi14?rel=0&modestbranding=1&allowfullscreen=1&iv_load_policy=3"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
      <Button color={'action'} onClick={onFinish}>
        Get started
        <ArrowRight />
      </Button>
      <img className={styles.leftGraphic} src={leftGraphic} alt="characters-left" />
      <img className={styles.rightGraphic} src={rightGraphic} alt="characters-right" />
    </div>
  )
}

export default Tutorial
