import React, { useState, useCallback } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { selectUser, uploadAvatar, updateUser } from 'store/services/Auth/authReducer'
import { AvatarUpload, Typography, Button, EditCircleIcon, Modal, PasswordChangeForm } from '@astrid/components'
import ProfileForm from './ProfileForm/ProfileForm'
import AuthApi from 'store/services/Auth/authApi'
import { ScreenNames } from 'analytics/analytics'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'

import styles from './Profile.module.scss'

const Profile = () => {
  useTrackScreenShown(ScreenNames.Profile)
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const uploadUserAvatar = useCallback(
    async (file: File) => {
      const formData = new FormData()
      formData.append('avatar', file)
      try {
        await dispatch(uploadAvatar(formData))
      } catch (error) {}
    },
    [dispatch]
  )

  const removeAvatar = useCallback(async () => {
    try {
      await dispatch(updateUser({ avatar: null }))
    } catch (error) {}
  }, [dispatch])

  const changePassword = useCallback(async (newPass: string) => {
    try {
      await AuthApi.changePassword(newPass)
      setModalOpen(false)
    } catch (error) {
      console.warn('Password change error')
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <AvatarUpload
        className={styles.upload}
        file={user?.profile?.avatar}
        uploadImage={uploadUserAvatar}
        removeImage={removeAvatar}
      />
      <div className={styles.content}>
        <Typography variant="h3">Account settings</Typography>
        <ProfileForm user={user!} />
        <Typography variant="h4">Security</Typography>
        <div className={styles.security}>
          {!user?.googleId && (
            <span onClick={() => setModalOpen(true)}>
              <Typography variant="body">Change password</Typography>
              <EditCircleIcon />
            </span>
          )}
        </div>
        <Button color="secondary" size="small" className={styles.logout} onClick={AuthApi.logout}>
          Logout
        </Button>
        <Modal open={modalOpen} hideCloseButton closeModal={() => setModalOpen(false)} variant="student">
          <PasswordChangeForm onSubmit={changePassword} onCancel={() => setModalOpen(false)} />
        </Modal>
      </div>
    </div>
  )
}

export default Profile
