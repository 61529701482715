import {
  AUTH_ACTIONS,
  AuthActions,
  AuthCredentials,
  AuthLoadUser,
  AuthState,
  TeacherProfile,
  TeacherProfileUpdate
} from 'store/services/Auth/types'
import { Dispatch } from 'redux'
import AuthApi from 'store/services/Auth/authApi'
import { Logger } from 'shared/logger/Logger'
import { RootState } from 'store/types'

export const AuthInitialState: AuthState = {
  user: undefined
}

const authReducer = (state: AuthState = AuthInitialState, action: AuthActions) => {
  switch (action.type) {
    case AUTH_ACTIONS.LOAD_DATA:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}

export default authReducer

// ACTIONS
export const loginUser = (data: AuthCredentials) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { token, user } = (await AuthApi.login(data)).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
    AuthApi.setUserToken(token)
    return user
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const createUser = (data: AuthCredentials) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { token, user } = (await AuthApi.create(data)).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
    AuthApi.setUserToken(token)
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const createProfile = (profile: TeacherProfile) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data } = await AuthApi.createProfile(profile)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user: data })
    return data
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const setOnboardinCompleted = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data } = await AuthApi.setOnboardingCompleted()
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user: data })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const setTutorialCompleted = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data } = await AuthApi.setTutorialCompleted()
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user: data })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const getUser = () => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const user = (await AuthApi.load()).data
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const updateUser = (updateData: TeacherProfileUpdate) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data } = await AuthApi.updateProfile(updateData)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user: data })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

export const uploadAvatar = (updateData: FormData) => async (dispatch: Dispatch<AuthLoadUser>) => {
  try {
    const { data } = await AuthApi.setProfileAvatar(updateData)
    dispatch({ type: AUTH_ACTIONS.LOAD_DATA, user: data })
  } catch (e) {
    Logger.log(e)
    throw new Error(e)
  }
}

// SELECTORS
export const selectUser = (state: RootState) => state.auth.user

export const selectUserCountry = (state: RootState) => state.auth.user?.profile?.country
