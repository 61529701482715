import { AxiosPromise } from 'axios'

import API from 'shared/api/API'
import { Book, IExercise, ScoreResponse } from '@astrid/components'
import { Paginated } from 'shared/api/types'
import { GetSanaScoreOptions } from '@astrid/components/src/types'

export const getAllBooks = (): AxiosPromise<Paginated<Book>> =>
  API.get('/book', {
    params: {
      $limit: 1000,
      $filter: 'platform:web'
    }
  })

export const getBookExercises = (bookId: string): AxiosPromise<IExercise[]> => API.get(`/book/${bookId}/exercises`)

export const sanaGetScore = ({ audio, phrase }: GetSanaScoreOptions): AxiosPromise<ScoreResponse> => {
  const formData = new FormData()
  formData.append('audio', audio)
  formData.append('target_phrase', phrase)
  formData.append('dialect', 'en-GB')

  return API.post('/sana/score', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}
