import React from 'react'
import { Link } from 'react-router-dom'

import { StudentClass } from 'store/services/Classes/types'
import { Typography } from '@astrid/components'
import { ROUTES } from 'routes/routes'
import styles from './ClassesList.module.scss'

interface ClassesListProps {
  classes: StudentClass[]
}

const ClassesList: React.FC<ClassesListProps> = ({ classes }) => {
  return (
    <table className={styles.container}>
      <thead>
        <tr className={styles.row}>
          <th>
            <Typography variant="h4">Name</Typography>
          </th>
          <th>
            <Typography variant="h4">Students</Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {classes.map((studentClass) => (
          <tr className={styles.row} key={studentClass._id}>
            <td>
              <Link to={`${ROUTES.CLASSES}/${studentClass._id}`}>{studentClass.name}</Link>
            </td>
            <td>{studentClass.users.length}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ClassesList
