import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select, ToggleSwitch, Headline } from '@astrid/components'

import { getClasses, selectClasses, setInsightClassId, selectInsightClassId } from 'store/services/Classes/reducer'
import {
  loadStatisticsByClassId,
  selectStatisticsByClassId,
  selectStatisticsReqState
} from 'store/services/Statistics/reducer'
import { selectUser } from 'store/services/Auth/authReducer'
import { RootState } from 'store/types'
import InsightsTable from './InsightsTable/InsightsTable'
import StudyTimeTable from './StudyTimeTable/StudyTimeTable'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'routes/routes'
import { loadStudyTimeByClassId, selectStudyTimeByClassId } from 'store/services/StudyTime/reducer'
import { getClassOverview, selectClassOverview, selectClassOverviewReqState } from 'store/services/Overview/reducer'
import { ApiReqState } from 'shared/api/types'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './Insights.module.scss'

interface State {
  mode: 'studyoverview' | 'thisweek'
  studyTimeWeekCount: number
  studyTimePage: number
}

const defaultState: State = {
  mode: 'thisweek',
  studyTimeWeekCount: 6,
  studyTimePage: 0
}

const Insights = () => {
  useTrackScreenShown(ScreenNames.InsightsOverview)
  // For now only showing insights for current week
  const displayMonth = false

  const history = useHistory()
  const teacher = useSelector(selectUser)
  const [state, setState] = useState<State>(defaultState)

  const dispatch = useDispatch()
  const selectedClassId = useSelector(selectInsightClassId)
  const statisticsReqState = useSelector(selectStatisticsReqState)

  const classes = useSelector(selectClasses)
  const currentStatistics = useSelector((reduxState: RootState) =>
    selectStatisticsByClassId(reduxState, selectedClassId)
  )
  const classStatistics = useSelector(selectClassOverview)
  const classStatisticsReqState = useSelector(selectClassOverviewReqState)
  const studyTimeData = useSelector((reduxState: RootState) => selectStudyTimeByClassId(reduxState, selectedClassId))
  const currentClass = classes.find((c) => c._id === selectedClassId)

  const classHasUsers = currentClass && currentClass.users.length > 0

  useEffect(() => {
    if (
      classes.length > 0 &&
      (selectedClassId.length === 0 || classes.findIndex((c) => c._id === selectedClassId) === -1)
    ) {
      dispatch(setInsightClassId(classes[0]._id))
    }
  }, [classes, dispatch, selectedClassId])

  useEffect(() => {
    if (classHasUsers) {
      if (state.mode === 'studyoverview') {
        const $limit = state.studyTimeWeekCount
        const $skip = state.studyTimePage * state.studyTimeWeekCount
        dispatch(loadStudyTimeByClassId(selectedClassId, $limit, $skip))
      } else {
        // GA_pageview(`/students/${state.displayMonth ? '4weeks' : 'week'}`)
        dispatch(loadStatisticsByClassId(selectedClassId, displayMonth))
        dispatch(getClassOverview(selectedClassId, displayMonth))
      }
    }
    // eslint-disable-next-line
  }, [state, selectedClassId])

  useEffect(() => {
    // GA_pageview(window.location.pathname)
    dispatch(getClasses())
  }, [dispatch])

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname === ROUTES.INSIGHTS) {
        setState(defaultState)
      }
    })
    return unlisten
  }, [history])

  const getStatus = () => {
    if (statisticsReqState === ApiReqState.RESOLVED && classStatisticsReqState === ApiReqState.RESOLVED) {
      return 'success'
    }
    if (statisticsReqState === ApiReqState.REJECTED || classStatisticsReqState === ApiReqState.REJECTED) {
      return 'error'
    }
    return 'loading'
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {teacher?.profile && (
          <Headline
            title={`${teacher.profile.firstName} ${teacher.profile.lastName}`}
            subtitle={teacher.profile.school}
            imgSrc={teacher.profile.avatar}
          />
        )}
        <div className={styles.filtersContainer}>
          <Select
            style={{ width: 450 }}
            name="class"
            testId="class-select-insights"
            className={styles.classSelect}
            value={selectedClassId}
            label="Class"
            onChange={(e) => dispatch(setInsightClassId(e.target.value))}
            options={classes.map(({ _id, name }) => ({ value: _id, label: name }))}
          />
          <div data-role="wrapper">
            {classHasUsers && (
              <ToggleSwitch
                className={styles.toggle}
                name="mode"
                falseLabel="This Week"
                trueLabel="Time Studied"
                value={state.mode === 'studyoverview'}
                onChange={() =>
                  setState((state) => ({
                    ...state,
                    mode: state.mode === 'studyoverview' ? 'thisweek' : 'studyoverview'
                  }))
                }
              />
            )}
          </div>
        </div>
      </div>
      {state.mode === 'studyoverview' ? (
        <StudyTimeTable
          classId={selectedClassId}
          studyTimeData={studyTimeData}
          onPrev={() => setState({ ...state, studyTimePage: state.studyTimePage + 1 })}
          onNext={() => setState({ ...state, studyTimePage: state.studyTimePage - 1 })}
          enableNext={state.studyTimePage > 0}
        />
      ) : (
        <InsightsTable
          status={getStatus()}
          selectedClass={currentClass}
          statistics={currentStatistics}
          total={classStatistics || undefined}
        />
      )}
    </div>
  )
}

export default Insights
