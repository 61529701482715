import * as React from 'react'
import { Tooltip, ReferenceLine } from 'recharts'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts'
import styles from 'shared/components/Charts/Chart.module.scss'
import Chart from 'shared/components/Charts/Chart'

export type FluencyData = Array<{ day: string; words: number | null }>

const chartProps = {
  margin: {
    top: 15,
    left: 5,
    right: 0
  }
}

const yAxisProps = { domain: [0, 100] }

export const FluencyChart: React.FC<{
  data: FluencyData
  goal?: number
  chartType?: string
}> = ({ data, goal, chartType = 'line' }) => {
  if (chartType === 'line') {
    return (
      <Chart
        id={'fluencyChart'}
        chartProps={chartProps}
        data={data}
        lineKey={'words'}
        lineGradient={{ start: '#18acb7', end: '#18acb7' }}
        yAxisProps={yAxisProps}>
        {goal && (
          <ReferenceLine
            y={goal}
            label={({ viewBox, content, ...props }) => {
              const positionX = 300 - 31
              return (
                <>
                  <rect x={positionX} y={viewBox.y - 8} width="31" height="14" fill={'#b5b5b5'} rx="4" />
                  <text {...viewBox} {...props} x={positionX}>
                    <tspan dy={2} dx={4} style={{ font: '10px sans-serif', fill: 'white' }}>
                      Goal
                    </tspan>
                  </text>
                </>
              )
            }}
            stroke={'rgba(10, 10, 10, 0.3)'}
            strokeDasharray="4"
          />
        )}
        {data.length > 0 && <Tooltip content={<FluencyTooltip />} />}
      </Chart>
    )
  }

  if (chartType === 'bar') {
    return (
      <BarChart width={300} height={300} data={data} {...chartProps}>
        <CartesianGrid vertical={false} />
        <XAxis tickLine={false} axisLine={false} dataKey="day" stroke={'#c8c8c8'} />
        <YAxis tickLine={false} axisLine={false} width={40} stroke={'#c8c8c8'} {...yAxisProps} />
        <Tooltip content={<FluencyTooltip />} />
        <Bar dataKey="words" fill="#18acb7" />
      </BarChart>
    )
  }

  return null
}

const FluencyTooltip: React.FC<any> = ({ active, payload, ...props }) => {
  if (active && payload && payload[0]) {
    const { words } = payload[0].payload
    return (
      <div className={styles.tooltip}>
        <p>Words: {~~words || 0}</p>
      </div>
    )
  }

  return null
}
