import { Dispatch } from 'redux'

import OverviewApi from './api'
import { Logger } from 'shared/logger/Logger'
import {
  OverviewState,
  OVERVIEW_ACTIONS,
  OverviewActions,
  LoadClassOverviewAction,
  LoadStudentOverviewAction,
  ClearOverviewAction,
  AnalyticsScore,
  EffortAnalytics,
  LoadStudentOverviewActionReqStateAction,
  LoadClassOverviewActionReqStateAction
} from './types'
import { RootState } from 'store/types'
import { ApiReqState } from 'shared/api/types'

export const OverviewInitialState: OverviewState = {
  classOverview: null,
  studentOverview: null,
  classReqState: ApiReqState.IDLE,
  studentReqState: ApiReqState.IDLE
}

const overviewReducer = (state: OverviewState = OverviewInitialState, action: OverviewActions) => {
  switch (action.type) {
    case OVERVIEW_ACTIONS.LOAD_STUDENT:
      return {
        ...state,
        studentOverview: action.data
      }
    case OVERVIEW_ACTIONS.LOAD_CLASS:
      return {
        ...state,
        classOverview: action.data
      }
    case OVERVIEW_ACTIONS.LOAD_CLASS_REQ_STATE:
      return {
        ...state,
        classReqState: action.reqState
      }
    case OVERVIEW_ACTIONS.LOAD_STUDENT_REQ_STATE:
      return {
        ...state,
        studentReqState: action.reqState
      }
    case OVERVIEW_ACTIONS.CLEAR:
      return {
        ...OverviewInitialState
      }
    default:
      return state
  }
}

export default overviewReducer

// Action creators:

const setGetStudentOverviewReqState = (reqState: ApiReqState): LoadStudentOverviewActionReqStateAction => ({
  type: OVERVIEW_ACTIONS.LOAD_STUDENT_REQ_STATE,
  reqState
})

const setGetClassOverviewReqState = (reqState: ApiReqState): LoadClassOverviewActionReqStateAction => ({
  type: OVERVIEW_ACTIONS.LOAD_CLASS_REQ_STATE,
  reqState
})

export const getStudentOverview = (studentId: string, month?: boolean) => async (
  dispatch: Dispatch<LoadStudentOverviewAction | LoadStudentOverviewActionReqStateAction>
) => {
  try {
    dispatch(setGetStudentOverviewReqState(ApiReqState.PENDING))
    // TODO: use Promise.allSettled to avoid breaking everything if one fails
    const [overview, progress, effort] = await Promise.all([
      OverviewApi.getStudentOverview(studentId, month),
      OverviewApi.getStudentProgressAnalytics(studentId, month),
      OverviewApi.getStudentEffortAnalytics(studentId, month)
    ])

    dispatch({
      type: OVERVIEW_ACTIONS.LOAD_STUDENT,
      data: { ...overview.data, analytics: { progress: progress.data, effort: effort.data } }
    })
    dispatch(setGetStudentOverviewReqState(ApiReqState.RESOLVED))
  } catch (error) {
    dispatch(setGetStudentOverviewReqState(ApiReqState.REJECTED))
    Logger.log(error)
  }
}

export const getClassOverview = (classId: string, month?: boolean) => async (
  dispatch: Dispatch<LoadClassOverviewAction | LoadClassOverviewActionReqStateAction>
) => {
  try {
    dispatch(setGetClassOverviewReqState(ApiReqState.PENDING))
    // TODO: use Promise.allSettled to avoid breaking everything if one fails
    const [overview, progress, effort] = await Promise.all([
      OverviewApi.getClassOverview(classId, month),
      OverviewApi.getClassProgressAnalytics(classId, month),
      OverviewApi.getClassEffortAnalytics(classId, month)
    ])

    dispatch({
      type: OVERVIEW_ACTIONS.LOAD_CLASS,
      data: { ...overview.data, analytics: { progress: progress.data, effort: effort.data } }
    })
    dispatch(setGetClassOverviewReqState(ApiReqState.RESOLVED))
  } catch (error) {
    dispatch(setGetClassOverviewReqState(ApiReqState.REJECTED))
    Logger.log(error)
  }
}

export const clearOverviewState = () => (dispatch: Dispatch<ClearOverviewAction>) => {
  dispatch({
    type: OVERVIEW_ACTIONS.CLEAR
  })
}

// Helpers
const weekDaysShort = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su']
const formatDate = (date: number) =>
  `${String(new Date(date).getDate()).padStart(2, '0')}.${String(new Date(date).getMonth() + 1).padStart(2, '0')}`

export const formatFluencyAnalytics = (data: AnalyticsScore[] | undefined, displayMonth?: boolean) => {
  if (!data) {
    return []
  }

  return [...data]
    .sort((a, b) => a.time - b.time)
    .map((e) => ({
      words: ~~e.score,
      day: displayMonth ? formatDate(e.time) : weekDaysShort[e.time - 1]
    }))
}

export const formatComprehensionAnalytics = (data: AnalyticsScore[] | undefined, displayMonth?: boolean) => {
  if (!data) {
    return []
  }

  return [...data]
    .sort((a, b) => a.time - b.time)
    .map((e) => ({
      value: ~~e.score,
      day: displayMonth ? formatDate(e.time) : weekDaysShort[e.time - 1]
    }))
}

export const formatEffortAnalytics = (data: EffortAnalytics | undefined, displayMonth?: boolean) => {
  if (!data) {
    return []
  }

  const { exercisesCompleted, booksCompleted, timeSpent } = data
  return [
    booksCompleted.userScore.map(({ time, score }) => ({ time, storiesCompleted: score })),
    exercisesCompleted.userScore.map(({ time, score }) => ({ time, exercisesCompleted: score })),
    [...timeSpent.userScore].sort((a, b) => a.time - b.time).map(({ time, score }) => ({ time, minutes: score }))
  ]
    .flat()
    .reduce((prev: any[], current) => {
      const { time, ...rest } = current

      if (!prev.some((e) => e.time === time)) {
        return [...prev, current]
      }

      return prev.map((e) => (e.time === time ? { ...e, ...rest } : e))
    }, [])
    .sort((a, b) => a.time - b.time)
    .map((e) => ({
      ...e,
      day: displayMonth ? formatDate(e.time) : weekDaysShort[e.time - 1]
    }))
}

// Selectors:
export const selectClassOverview = (state: RootState) => state.overview.classOverview
export const selectStudentOverview = (state: RootState) => state.overview.studentOverview
export const selectClassOverviewReqState = (state: RootState) => state.overview.classReqState
export const selectStudentOverviewReqState = (state: RootState) => state.overview.studentReqState
