export const ROUTES = {
  ROOT: '/',
  REGISTER: '/register',
  ACTIVATE_EMAIL: '/activate',
  VERIFY_EMAIL: '/auth/verify',
  BOOK: '/book',
  LIBRARY: '/library',
  STUDENT: '/insights/student',
  CLASS: '/insights/class',
  CLASSES: '/classes',
  CLASSES_CREATE: '/classes/create',
  RESET_PASSWORD: '/auth/reset-pass/:resetToken',
  INSIGHTS: '/insights',
  PROFILE: '/profile'
}
