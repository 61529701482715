import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

import { ROUTES } from 'routes/routes'
import { Headline, Select, Typography } from '@astrid/components'
import {
  getStudentOverview,
  selectStudentOverview,
  selectStudentOverviewReqState
} from 'store/services/Overview/reducer'
import OverviewApi, { StudentProfile } from 'store/services/Overview/api'
import Overview from 'shared/components/Overview/Overview'
import { selectCurrentClass, getCurrentClass } from 'store/services/Classes/reducer'
import { ApiReqState } from 'shared/api/types'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './StudentOverview.module.scss'

interface OverviewComponentState {
  studentProfile: StudentProfile | null
}

const StudentOverview = ({ match }: RouteComponentProps<{ classId: string; id: string }>) => {
  useTrackScreenShown(ScreenNames.InsightsStudent)
  // For now this overview is only for current week
  const displayMonth = false

  const [state, setState] = useState<OverviewComponentState>({
    studentProfile: null
  })

  const studentId = match.params.id
  const classId = match.params.classId
  const currentClass = useSelector(selectCurrentClass)
  const studentOverview = useSelector(selectStudentOverview)
  const studentOverviewReqState = useSelector(selectStudentOverviewReqState)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getStudentOverview(studentId, displayMonth))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [studentId, history, displayMonth, dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCurrentClass(classId))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [classId, history, dispatch])

  useEffect(() => {
    // GA_pageview('/student')
    ;(async () => {
      try {
        const { data } = await OverviewApi.getStudentProfile(studentId)
        setState((state) => ({ ...state, studentProfile: data }))
      } catch (error) {
        history.push(ROUTES.INSIGHTS)
      }
    })()
  }, [studentId, history])

  if (!state.studentProfile || !currentClass) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Headline
          title={`${state.studentProfile.firstName} ${state.studentProfile.lastName}`}
          subtitle={`${currentClass.name}${
            state.studentProfile.school.name !== '' ? `, ${state.studentProfile.school.name}` : ''
          }`}
          imgSrc={state.studentProfile.avatar}
        />
        <div data-role="wrapper">
          <Select
            className={styles.classSelect}
            label="Student"
            name="student"
            value={studentId}
            onChange={(e) => history.push(`${ROUTES.STUDENT}/${classId}/${e.target.value}`)}
            options={currentClass.users.map((stud) => ({
              label: `${stud.profile.firstName} ${stud.profile.lastName}`,
              value: stud._id
            }))}
          />
        </div>
      </div>
      {studentOverviewReqState === ApiReqState.PENDING && (
        <div className={styles.message}>
          <CircularProgress className={styles.spinner} size={18} thickness={4} />
          <Typography variant="body" className={styles.message}>
            Loading...
          </Typography>
        </div>
      )}
      {studentOverviewReqState === ApiReqState.REJECTED && (
        <Typography variant="body" className={styles.message}>
          Failed to load data
        </Typography>
      )}
      {studentOverviewReqState === ApiReqState.RESOLVED && studentOverview && (
        <Overview
          overview={studentOverview}
          displayMonth={displayMonth}
          showChallengingWordsToPronounce={true}
          showChallengingWordsToSpell={true}
          showChallengingSounds={true}
          showTrending={true}
          showOnlyTimeEffort={false}
        />
      )}
    </div>
  )
}

export default StudentOverview
