import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import StudentsTable from 'Classes/StudentsTable/StudentsTable'
import { Typography, Modal, CancelConfirmButtons, AlertContext } from '@astrid/components'
import { SingleStudentFormValues } from 'Classes/StudentsTable/components/SingleStudentForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentClass,
  selectCurrentClass,
  addStudentToClass,
  editStudent,
  removeStudentFromClass,
  addMultipleStudents,
  clearCurrentClass,
  selectCurrentClassReqState
} from 'store/services/Classes/reducer'
import { RouteComponentProps } from 'react-router-dom'
import { CreateStudentData, StudentsTableRow } from 'store/services/Classes/types'
import { CircularProgress, Dialog, Slide } from '@material-ui/core'
import AuthApi from 'store/services/Auth/authApi'
import UploadClassModal from 'Classes/UploadClassModal/UploadClassModal'
import SheetPreview from 'Classes/SheetPreview/SheetPreview'
import { TransitionProps } from '@material-ui/core/transitions/transition'
import ClassesApi from 'store/services/Classes/api'
import { formatStudentDataForApi, transformStudentsFromApiToTable } from 'store/services/Classes/utils'
import ClassViewHeader from './components/ClassViewHeader'
import { printApiMessage } from 'shared/api/apiMessages'
import { ApiReqState } from 'shared/api/types'
import { useTrackScreenShown } from 'hooks/useTrackScreenShown'
import { ScreenNames } from 'analytics/analytics'

import styles from './ClassView.module.scss'

const PreviewDialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ClassView = ({ match }: RouteComponentProps<{ id: string }>) => {
  useTrackScreenShown(ScreenNames.Class)

  const { id } = match.params
  const dispatch = useDispatch()
  const currentClassReqState = useSelector(selectCurrentClassReqState)
  const currentClass = useSelector(selectCurrentClass)
  const { showAlert } = useContext(AlertContext)
  const [studentToDelete, setStudentToDelete] = useState<StudentsTableRow | null>(null)
  const [uploadStudentsModalOpen, setUploadStudentsModalOpen] = useState(false)
  const [emailParentsModalOpen, setEmailParentsModalOpen] = useState(false)
  const [parsedStudents, setParsedStudents] = useState<CreateStudentData[]>([])
  const [sheetPreviewDialogOpen, setSheetPreviewDialogOpen] = useState(false)

  const students: StudentsTableRow[] = useMemo(() => {
    if (currentClass) {
      return transformStudentsFromApiToTable(currentClass.users)
    } else {
      return []
    }
  }, [currentClass])

  const onAddStudent = useCallback(
    async (values: SingleStudentFormValues) => {
      try {
        await dispatch(addStudentToClass(id, formatStudentDataForApi(values)))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert]
  )

  const closeSheetDialog = useCallback(() => {
    setParsedStudents([])
    setSheetPreviewDialogOpen(false)
  }, [])

  const onAddMultipleStudents = useCallback(
    async (values: SingleStudentFormValues[]) => {
      const students = values.map(formatStudentDataForApi)
      try {
        await dispatch(addMultipleStudents(id, students))
        closeSheetDialog()
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert, closeSheetDialog]
  )

  const onEditStudent = useCallback(
    async (studentId: string, values: SingleStudentFormValues) => {
      try {
        await dispatch(editStudent(id, studentId, formatStudentDataForApi(values)))
      } catch (error) {
        showAlert(printApiMessage(error))
      }
    },
    [dispatch, id, showAlert]
  )

  const onDeleteStudent = useCallback(async () => {
    if (studentToDelete) {
      try {
        await dispatch(removeStudentFromClass(id, studentToDelete._id))
        setStudentToDelete(null)
      } catch (error) {}
    }
  }, [studentToDelete, id, dispatch])

  const onEmailParents = useCallback(async () => {
    try {
      await ClassesApi.sendCredentialsEmailToParents(id)
      setEmailParentsModalOpen(false)
    } catch (error) {
      showAlert('Sending email to parents failed. Please try again.')
    }
  }, [id, showAlert])

  const exportStudentsUrl = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/export/students-class/${id}/?token=${AuthApi.getUserToken()}`
  }, [id])

  const closeStudentsAddModal = useCallback(() => {
    setUploadStudentsModalOpen(false)
  }, [])

  const onFileUpload = useCallback(
    async (file: File) => {
      try {
        const { data } = await ClassesApi.parseSheet(id, file)
        closeStudentsAddModal()
        setParsedStudents(data.parsedStudents)
        setSheetPreviewDialogOpen(true)
      } catch (error) {
        showAlert('Document parsing failed. Please check your sheet or try another one.')
      }
    },
    [closeStudentsAddModal, id, showAlert]
  )

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(getCurrentClass(id))
      } catch (error) {}
    })()

    return () => {
      dispatch(clearCurrentClass)
    }
  }, [id, dispatch])

  if (!currentClass) {
    return (
      <>
        {currentClassReqState === ApiReqState.PENDING && (
          <div className={styles.message}>
            <CircularProgress className={styles.spinner} size={18} thickness={4} />
            <Typography variant="body" className={styles.message}>
              Loading class...
            </Typography>
          </div>
        )}
        {currentClassReqState === ApiReqState.REJECTED && (
          <Typography variant="body" className={styles.message}>
            Failed to load class.
          </Typography>
        )}
      </>
    )
  }

  return (
    <section className={styles.container}>
      <ClassViewHeader classId={id} name={currentClass.name} cefrLevel={currentClass.cefrLevel} />
      <StudentsTable
        students={students}
        onAddStudent={onAddStudent}
        onEditStudent={onEditStudent}
        exportStudentsUrl={exportStudentsUrl}
        onDeleteStudent={setStudentToDelete}
        onEmailParents={() => setEmailParentsModalOpen(true)}
        onUploadClassList={() => setUploadStudentsModalOpen(true)}
      />
      <Modal variant="teacher" open={!!studentToDelete} hideCloseButton closeModal={() => setStudentToDelete(null)}>
        <Typography variant="exerciseS">
          Are you sure you want to delete {`${studentToDelete?.firstName} ${studentToDelete?.lastName} account?`}
        </Typography>
        <CancelConfirmButtons
          onConfirm={onDeleteStudent}
          onCancel={() => setStudentToDelete(null)}
          confirmText="DELETE"
        />
      </Modal>
      <Modal open={uploadStudentsModalOpen} hideCloseButton variant="teacher" closeModal={closeStudentsAddModal}>
        <UploadClassModal closeModal={closeStudentsAddModal} onFileUpload={onFileUpload} />
      </Modal>
      <Modal
        open={emailParentsModalOpen}
        variant="teacher"
        hideCloseButton
        closeModal={() => setEmailParentsModalOpen(false)}>
        <Typography variant="h3" style={{ marginBottom: 0 }}>
          Email parents
        </Typography>
        <Typography variant="body">
          Each student's login information will be emailed to their parent(s). Please note that no email will be sent
          for students without a parent email.
        </Typography>
        <CancelConfirmButtons
          onCancel={() => setEmailParentsModalOpen(false)}
          onConfirm={onEmailParents}
          confirmText="Send"
        />
      </Modal>
      <Dialog
        fullScreen
        open={sheetPreviewDialogOpen}
        onClose={closeSheetDialog}
        TransitionComponent={PreviewDialogTransition}>
        <SheetPreview
          onAddMultipleStudents={onAddMultipleStudents}
          parsedStudents={parsedStudents}
          onClosePreview={closeSheetDialog}
        />
      </Dialog>
    </section>
  )
}

export default ClassView
