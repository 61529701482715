import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'

interface ApiServiceConfig {
  API_URL?: string
  DEFAULT_HTTP_HEADERS: { [key: string]: string }
  ACCESS_TOKEN_KEY: string
  GET_ACCESS_TOKEN: () => string | null
}

class ApiService {
  private _service!: AxiosInstance

  constructor({ API_URL, DEFAULT_HTTP_HEADERS, ACCESS_TOKEN_KEY, GET_ACCESS_TOKEN }: ApiServiceConfig) {
    if (!API_URL) {
      throw new Error('API_URL is required')
    }

    if (!ACCESS_TOKEN_KEY) {
      throw new Error('ACCESS_TOKEN_KEY is required')
    }

    const service = axios.create({
      baseURL: API_URL,
      validateStatus: (status) => status >= 200 && status < 300,
      headers: DEFAULT_HTTP_HEADERS || { 'Content-Type': 'application/json' }
    })

    // Add access token to every request if it exist
    service.interceptors.request.use(
      (config) => {
        const accessToken = GET_ACCESS_TOKEN()

        if (accessToken) {
          config.headers = { ...config.headers, [ACCESS_TOKEN_KEY]: `${accessToken}` }
        }

        return config
      },
      (error) => Promise.reject(error)
    )

    // Add global handlers for success and error response
    service.interceptors.response.use(this.handleSuccess, (err) => Promise.reject(this.handleError(err)))
    this.service = service
  }

  handleSuccess(response: AxiosResponse): AxiosResponse {
    return response
  }

  handleError(error: AxiosError<{ error: any }>): AxiosError | string | object {
    const message = 'Something went wrong... Please try again.'

    if (!error) {
      return message
    }

    if (error.response && error.response.data) {
      return error.response.data.error
    }

    if (error.message) {
      return error.message
    }
    return error
  }

  get service(): AxiosInstance {
    return this._service
  }

  set service(service: AxiosInstance) {
    this._service = service
  }
}

export default ApiService
