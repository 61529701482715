import React, { useRef, useState } from 'react'
import { EmailIcon, ShareIcon, Typography } from '@astrid/components'
import { Popover } from '@material-ui/core'
import { ReactComponent as AddIcon } from 'shared/assets/plus-circle.svg'
import { ReactComponent as ExportIcon } from 'shared/assets/export.svg'
import { ReactComponent as ExcelIcon } from 'shared/assets/excel.svg'

import styles from '../StudentsTable.module.scss'

interface StudentTableToolbarProps {
  onAddStudent: () => void
  exportStudentsUrl: string
  studentsCount: number
  onUploadClassList: () => void
  onEmailParents: () => void
}

const StudentTableToolbar: React.FC<StudentTableToolbarProps> = ({
  onAddStudent,
  exportStudentsUrl,
  studentsCount,
  onUploadClassList,
  onEmailParents
}) => {
  const popoverAnchorEl = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  return (
    <div className={styles.toolbar}>
      <Typography variant="h4">Student list</Typography>
      <div>
        <button className={styles.toolbar__item} onClick={onAddStudent} data-testid="student-add-button">
          <AddIcon /> Add student
        </button>
        <button className={styles.toolbar__item} onClick={onUploadClassList}>
          <ExcelIcon /> Upload class list
        </button>
        {studentsCount > 0 && (
          <>
            <button className={styles.toolbar__item_share} ref={popoverAnchorEl} onClick={() => setPopoverOpen(true)}>
              <ShareIcon /> Share student logins
            </button>
            <Popover
              open={popoverOpen}
              anchorEl={popoverAnchorEl.current}
              onClose={() => setPopoverOpen(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <div className={styles.toolbar__popover}>
                <a
                  className={styles.toolbar__popover__item}
                  download="students_logins"
                  href={exportStudentsUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  <ExportIcon /> Download login PDF
                </a>
                <button className={styles.toolbar__popover__item} onClick={onEmailParents}>
                  <EmailIcon /> Email parents
                </button>
              </div>
            </Popover>
          </>
        )}
      </div>
    </div>
  )
}

export default StudentTableToolbar
